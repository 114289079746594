import styled from "@emotion/styled";
import React, { CSSProperties, FC, ReactNode, useRef, useEffect } from "react";
import CircularProgress from "../Loading/CircularProgress";
import { motion } from "framer-motion";
import { useTooltip } from "../../providers/TooltipProvider";

export type ButtonProps = {
  color?: string | "blue" | "red" | "transparent" | "white";
  badge?: string | number;
  children?: ReactNode;
  rounded?: "xl";
  size?: "large";
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  title?: string;
  tabIndex?: number;
  className?: string;
  id?: string;
  tooltip?: string;
  "data-testid"?: string;
};

interface StyledProps {
  color?: "blue" | "red" | "green" | "transparent" | "white" | string;
  size?: "large";
  rounded?: "xl";
  fullWidth?: boolean;
}

const shouldForwardProp = (prop: any) =>
  prop !== "color" && prop !== "size" && prop !== "rounded" && prop !== "fullWidth";

export const ButtonStyled = styled(motion.button, {
  shouldForwardProp,
})<StyledProps>(({ color, size, rounded, fullWidth }) => ({
  minWidth: 80,
  flexShrink: "0",
  position: "relative",
  webkitBoxAlign: "baseline",
  userSelect: "none",
  alignItems: "center",
  border: "none",
  borderRadius: 3,
  ...(rounded &&
    rounded === "xl" && {
      borderRadius: 8,
    }),
  boxSizing: "border-box",
  display: "inline-flex",
  fontSize: 14,
  fontWeight: 500,
  textAlign: "center",
  textDecoration: "none",
  transition: "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
  whiteSpace: "nowrap",
  background: "rgba(9,30,66,0.04)",
  cursor: "pointer",
  height: "2.28571em",
  lineHeight: "2.28571em",
  padding: "0 10px",
  width: "auto",
  justifyContent: "center",
  WebkitBoxPack: "center",
  gap: "0 0.3rem",
  color: "#42526e",
  "& svg": {
    fontSize: "inherit",
  },
  "&:hover": {
    backgroundColor: "rgba(9,30,66,0.08)",
    transitionDuration: "0s, 0.15s",
    textDecoration: "inherit",
    color: "#42526e",
  },
  ...(color &&
    color === "blue" && {
      color: "#fff",
      background: "rgb(0,82,204)",
      "&:hover": {
        background: "rgb(0,101,255)",
        textDecoration: "inherit",
        transitionDuration: "0s, 0.15s",
      },
    }),
  ...(color &&
    color === "red" && {
      color: "#fff",
      background: "#ff4747",
      "&:hover": {
        background: "#ff6161",
        textDecoration: "inherit",
        transitionDuration: "0s, 0.15s",
      },
    }),
  ...(color &&
    color === "green" && {
      color: "#fff",
      background: "#3bbd51",
      "&:hover": {
        background: "#268c37",
        textDecoration: "inherit",
        transitionDuration: "0s, 0.15s",
      },
    }),
  ...(color &&
    color === "transparent" && {
      background: "none",
      "&:hover": {
        background: "rgba(9,30,66,0.08)",
        transitionDuration: "0s, 0.15s",
      },
    }),
  ...(color &&
    color === "white" && {
      background: "#fff",
      color: "#333",
      "&:hover": {
        background: "rgb(230,230,230)",
        color: "#333",
      },
    }),
  ...(size &&
    size === "large" && {
      height: 45.12,
    }),
  ...(fullWidth && {
    width: "100%",
    maxWidth: "100%",
  }),
  "&:disabled": {
    cursor: "not-allowed",
  },
}));

const Badge = styled("div")(() => ({
  position: "absolute",
  top: -4,
  right: -4,
  borderRadius: 9999,
  boxShadow: "0 1px 2px rgba(0,0,0,.3)",
  backgroundColor: "gray",
  height: 16,
  width: 16,
  paddingTop: 1,
  fontSize: 11,
  color: "#fff",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const Button: FC<ButtonProps> = (props) => {
  const { loading, badge, tooltip: isTooltip, disabled, ...rest } = props;
  const tooltip = useTooltip();
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleMouseLeave = () => {
      if (isTooltip !== undefined) {
        tooltip.setShow(false);
        tooltip.setText("");
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isTooltip !== undefined) {
        if (e.clientX + 200 >= window.innerWidth) {
          tooltip.x.set(e.clientX - 200);
        } else {
          tooltip.x.set(e.clientX);
        }

        if (e.clientY + 20 >= window.innerHeight) {
          tooltip.y.set(e.clientY - 20);
        } else {
          tooltip.y.set(e.clientY + 20);
        }
      }
    };

    const handleMouseEnter = (e: MouseEvent) => {
      if (isTooltip !== undefined) {
        if (e.clientX + 200 >= window.innerWidth) {
          tooltip.x.set(e.clientX - 200);
        } else {
          tooltip.x.set(e.clientX);
        }

        if (e.clientY + 20 >= window.innerHeight) {
          tooltip.y.set(e.clientY - 20);
        } else {
          tooltip.y.set(e.clientY + 20);
        }

        tooltip.setShow(true);
        tooltip.setText(isTooltip);
      }
    };

    const copy = ref.current;

    if (copy && isTooltip !== undefined) {
      copy.addEventListener("mousemove", handleMouseMove, true);
      copy.addEventListener("mouseleave", handleMouseLeave, true);
      copy.addEventListener("mouseenter", handleMouseEnter, true);
    }

    return () => {
      if (copy && isTooltip !== undefined) {
        copy.removeEventListener("mousemove", handleMouseMove, true);
        copy.removeEventListener("mouseleave", handleMouseLeave, true);
        copy.removeEventListener("mouseenter", handleMouseEnter, true);
      }
    };
  }, [isTooltip, tooltip]);

  return (
    <ButtonStyled
      ref={ref}
      disabled={disabled}
      initial={{ opacity: 0 }}
      animate={{ opacity: disabled ? 0.4 : 1 }}
      exit={{ opacity: 0 }}
      {...rest}
      data-testid={props["data-testid"] || "button"}
    >
      {typeof badge !== "undefined" && <Badge data-testid={"button-badge"}>{badge}</Badge>}
      {!loading && props.children}
      {loading && (
        <div className={"absolute top-0 left-0 right-0 bottom-0 flex flex-row justify-center items-center flex-nowrap"}>
          <CircularProgress height={12} width={12} />
        </div>
      )}
    </ButtonStyled>
  );
};

export default Button;

import { useAuth } from "../providers/AuthProvider";
import { useEffect, useMemo, useState } from "react";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class Zuschussart {
  uuid: string;
  art: string;
  created: string;
  updated: string;
}

type UseZuschussarten = {
  zuschussarten: Zuschussart[];
  loading: boolean;
  READ_ZUSCHUSSARTEN: boolean;
};

type Props = {
  loadZuschussarten?: boolean;
};

export const useZuschussarten = (props: Props = { loadZuschussarten: true }): UseZuschussarten => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [zuschussarten, setZuschussarten] = useState<Zuschussart[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const READ_ZUSCHUSSARTEN = useMemo(() => Boolean(auth.user?.permissions.includes("READ_ZUSCHUSSARTEN")), [auth.user]);

  useEffect(() => {
    let cancel = false;

    if (READ_ZUSCHUSSARTEN) {
      if (props.loadZuschussarten) {
        if (!cancel) {
          setLoading(true);
        }
        axios
          .get<Zuschussart[]>("/zuschussarten")
          .then((response) => {
            if (!cancel) {
              setZuschussarten(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        if (!cancel) {
          setLoading(false);
        }
      }
    } else {
      if (!cancel) {
        setLoading(false);
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_ZUSCHUSSARTEN, props.loadZuschussarten, handleApiError]);

  return {
    zuschussarten,
    loading,
    READ_ZUSCHUSSARTEN,
  };
};

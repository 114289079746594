import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingPage, useAuth } from "./AuthProvider";
import { Dayjs } from "dayjs";
import { useMessages } from "./MessageProvider";
import Portal from "../components/Layout/Portal";
import { AnimatePresence } from "framer-motion";
import { SubmittedCardBox } from "../components/Shop";
import DotFlashing from "../components/Loading/DotFlashing";
import { Helmet } from "react-helmet-async";
import Logo from "../components/Layout/Logo";
import { HomeLink } from "../components/Navigation/Navigation";
import { MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Backdrop from "../components/Modal/Backdrop";
import Eyes from "../components/Eyes";
import AddToCartModal from "../components/Shop/AddToCartModal";
import { useSidebar } from "./SidebarProvider";
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Min,
  ValidateNested,
} from "class-validator";
import { ShopContainerStyled } from "../components/Shop/ShopContainer";
import { Type } from "class-transformer";
import { IKantine, useKantinen } from "../hooks/useKantinen";
import { IBenutzer } from "../hooks/useBenutzer";
import axios from "axios";
import { VendorArtikelEntity, VendorEntity } from "../hooks/useVendors";

export class ShopOrderdayEntity {
  uuid: string;

  @IsArray()
  @ArrayMinSize(1)
  @Type(() => ShopArtikelEntity)
  @ValidateNested({ each: true })
  artikel: ShopArtikelEntity[];
  authorization_required: boolean;
  authorized: boolean;
  authorized_at: string | null;
  rejected: boolean;
  rejected_by: IBenutzer | null;
  rejected_at: string | null;
  authorized_by: IBenutzer | null;
  bcNo: string | null;
  zentralkueche: VendorEntity | null;

  @IsOptional()
  @IsBoolean()
  approved: boolean;
  approvedAt: string | null;
  approvedBy: IBenutzer;
  vendorNo: string | null;
  vendorName: string | null;
  deliveryDate: string | null;
  date: string;
  benutzer: IBenutzer;
  kantine: IKantine;
  created: string;
  updated: string;
  deleted: string | null;

  gelieferteArtikel?: VendorArtikelEntity[];
}

export interface ArtikelEinheit {
  Item_No: string;
  Code: string;
  Qty_per_Unit_of_Measure: number;
  AMA_UoM_Net_Filling_Weight: string;
  AMA_Net_Filling_Weight: number;
  Height: number;
  Width: number;
  Length: number;
  Cubage: number;
  Weight: number;
  ItemUnitOfMeasure: string;
  ItemBaseUOMQtyPrecision: number;
}

export class ShopArtikelEntity {
  uuid: string;
  orderday: ShopOrderdayEntity;
  category: string;
  @IsOptional()
  @IsNumber()
  received: number | null;
  orderedPrice: number;

  @IsNotEmpty()
  @IsNumber()
  @Min(10000)
  Line_No: number;

  @IsNotEmpty()
  @IsNumber()
  Last_Direct_Cost: number;

  @IsNotEmpty()
  @IsString()
  No: string;

  @IsString()
  Description: string;

  @IsString()
  Description2: string;

  @IsNotEmpty()
  @IsString()
  Base_Unit_of_Measure: string;

  @IsString()
  GTIN: string;

  @IsNotEmpty()
  @IsString()
  Item_Category_Code: string;

  @IsNotEmpty()
  @IsString()
  Search_Description: string;

  @IsNotEmpty()
  @IsNumber()
  Net_Weight: number;

  @IsNotEmpty()
  @IsNumber()
  Gross_Weight: number;

  @IsNotEmpty()
  @IsString()
  Vendor_No: string;

  @IsNotEmpty()
  @IsString()
  AMA_VendorName: string;

  @IsNotEmpty()
  @IsString()
  Vendor_Item_No: string;

  @IsNotEmpty()
  @IsNumber()
  Unit_Price: number;

  @IsNotEmpty()
  @IsNumber()
  quantity: number;
  created: string;
  updated: string;
  deleted: string | null;
}

export interface ICartVendor {
  no: string;
  name: string;
  date: Dayjs | null;
}

interface IUseShopProvider {
  cart: VendorArtikelEntity[];
  setCart: Dispatch<SetStateAction<VendorArtikelEntity[]>>;
  vendors: VendorEntity[];
  vendorsLoading: boolean;
  orderdays: ShopOrderdayEntity[];
  setOrderdays: Dispatch<SetStateAction<ShopOrderdayEntity[]>>;
  removeCartArtikel: (artikel: VendorArtikelEntity) => void;
  addLineNumbers: (items: ShopArtikelEntity[]) => ShopArtikelEntity[];
  addToCart: (artikel: VendorArtikelEntity, value: number) => void;
  showSubmitError: boolean;
  showSubmitSuccess: boolean;
  cartVendors: ICartVendor[];
  submitOrder: () => void;
  submitLoading: boolean;
  addedToCartSuccess: boolean;
  setCartVendors: Dispatch<SetStateAction<ICartVendor[]>>;
  loading: boolean;
  detailArtikel: VendorArtikelEntity | null;
  setDetailArtikel: Dispatch<SetStateAction<VendorArtikelEntity | null>>;
  clearCart: () => void;
  handleKantineChange: (uuid: string | null) => void;
  kantinen: IKantine[];
  selectedKantine: IKantine | null;
  addMultipleToCart: (artikel: VendorArtikelEntity[]) => void;
}

const ShopContext = createContext({} as IUseShopProvider);
export const useShop = () => useContext(ShopContext);
const useShopProvider = () => {
  const mounted = useRef<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { setError, handleApiError } = useMessages();
  const { kantinen } = useKantinen();
  const [loading, setLoading] = useState<boolean>(true);
  const [cart, setCart] = useState<VendorArtikelEntity[]>([]);
  const [vendors, setVendors] = useState<VendorEntity[]>([]);
  const [vendorsLoading, setVendorsLoading] = useState<boolean>(true);
  const [orderdays, setOrderdays] = useState<ShopOrderdayEntity[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState<boolean>(false);
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const [addedToCartSuccess, setAddedToCartSuccess] = useState<boolean>(false);
  const [cartVendors, setCartVendors] = useState<ICartVendor[]>([]);
  const [detailArtikel, setDetailArtikel] = useState<VendorArtikelEntity | null>(null);
  const [selectedKantine, setSelectedKantine] = useState<IKantine | null>(auth.user?.kantine || null);

  useEffect(() => {
    mounted.current = true;
    const local = localStorage.getItem("central-cart");
    if (local) {
      setCart(JSON.parse(local));
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    let cancel = false;

    if (auth.loggedIn && auth.user !== null) {
      Promise.all([
        axios.get<VendorEntity[]>("/shop/artikel-vendors"),
        axios.get<ShopOrderdayEntity[]>("/shop/orderdays"),
      ])
        .then((response) => {
          if (!cancel) {
            setVendors(response[0].data);
            setOrderdays(response[1].data);
          }
        })
        .catch(handleApiError)
        .finally(() => {
          if (!cancel) {
            setVendorsLoading(false);
            setLoading(false);
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [auth.loggedIn, auth.user, handleApiError]);

  useEffect(() => {
    const returnvalue: ICartVendor[] = [];

    for (let i = 0; i < cart.length; i++) {
      if (!Boolean(returnvalue.find((vendor) => vendor.no === cart[i].Vendor_No))) {
        returnvalue.push({
          no: cart[i].Vendor_No,
          name: cart[i].AMA_VendorName,
          date: null,
        });
      }
    }

    setCartVendors(returnvalue);
  }, [cart]);

  const submitOrder = () => {
    if (submitLoading) {
      return;
    }

    for (let i = 0; i < cartVendors.length; i++) {
      if (cartVendors[i].date === null || !cartVendors[i].date) {
        setError("Alle Bestellungen müssen ein Lieferdatum erhalten!");
        const element = document.getElementById("vendor-picker-" + cartVendors[i].no) as HTMLElement;
        if (element) {
          element.scrollIntoView();
          element.focus();
        }
        return;
      }
    }

    setSubmitLoading(true);

    axios
      .post("/shop", {
        artikel: [...cart],
        lieferanten: cartVendors.map((vendor) => ({ no: vendor.no, date: vendor.date?.format("YYYY-MM-DD") })),
      })
      .then(() => {
        axios.get<ShopOrderdayEntity[]>("/shop/orderdays").then((response) => {
          setOrderdays(response.data);
        });

        setCart([]);
        localStorage.setItem("central-cart", JSON.stringify([]));

        navigate("/shop");
        setSubmitLoading(false);
        setTimeout(() => {
          if (mounted.current) {
            setShowSubmitSuccess(true);
            setTimeout(() => {
              if (mounted.current) {
                setShowSubmitSuccess(false);
              }
            }, 3000);
          }
        }, 300);
      })
      .catch(() => {
        setSubmitLoading(false);
        if (mounted.current) {
          setTimeout(() => {
            if (mounted.current) {
              setShowSubmitError(true);
              setTimeout(() => {
                if (mounted.current) {
                  setShowSubmitError(false);
                }
              }, 3000);
            }
          }, 300);
        }
      });
  };

  const addLineNumbers = (items: ShopArtikelEntity[]) => {
    const copy = [...items];
    const vendorLines: { vendorno: string; index: number }[] = [];

    for (let i = 0; i < copy.length; i++) {
      if (vendorLines.length === 0) {
        vendorLines.push({
          vendorno: copy[i].Vendor_No,
          index: 1,
        });
        copy[i].Line_No = 10000;
        continue;
      }

      let found = false;

      for (let x = 0; x < vendorLines.length; x++) {
        if (copy[i].Vendor_No === vendorLines[x].vendorno) {
          vendorLines[x].index += 1;
          copy[i].Line_No = vendorLines[x].index * 10000;
          found = true;
          break;
        }
      }

      if (!found) {
        vendorLines.push({
          vendorno: copy[i].Vendor_No,
          index: 1,
        });
        copy[i].Line_No = 10000;
      }
    }

    return copy;
  };

  const addMultipleToCart = (artikel: VendorArtikelEntity[]) => {
    const copy = [...cart];

    for (let x = 0; x < artikel.length; x++) {
      let found = false;

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].No === artikel[x].No && copy[i].Vendor_No === artikel[x].Vendor_No) {
          copy[i].quantity = copy[i].quantity !== undefined ? copy[i].quantity! + 1 : 1;
          found = true;
          break;
        }
      }

      if (!found) {
        copy.push(artikel[x]);
      }
    }

    setCart(copy);

    localStorage.setItem("central-cart", JSON.stringify(copy));

    setTimeout(() => {
      if (mounted.current) {
        setAddedToCartSuccess(true);
        setTimeout(() => {
          if (mounted.current) {
            setAddedToCartSuccess(false);
          }
        }, 3000);
      }
    }, 300);
  };

  const addToCart = (atk: VendorArtikelEntity, value: number) => {
    const copy = [...cart];

    let found = false;
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].No === atk.No && copy[i].Vendor_No === atk.Vendor_No) {
        copy[i].quantity = value;
        found = true;
        break;
      }
    }

    if (!found) {
      copy.push({ ...atk, quantity: value });
    }

    setCart(copy);
    localStorage.setItem("central-cart", JSON.stringify(copy));

    setTimeout(() => {
      if (mounted.current) {
        setAddedToCartSuccess(true);
        setTimeout(() => {
          if (mounted.current) {
            setAddedToCartSuccess(false);
          }
        }, 3000);
      }
    }, 300);
  };

  const removeCartArtikel = (atk: VendorArtikelEntity) => {
    const copy = [...cart];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].No === atk.No && copy[i].Vendor_No === atk.Vendor_No) {
        copy.splice(i, 1);
        break;
      }
    }
    setCart(copy);
    localStorage.setItem("central-cart", JSON.stringify(copy));
  };

  const handleKantineChange = (uuid: string | null) => {
    if (uuid === null) {
      setSelectedKantine(null);
      return;
    }

    const kantine = auth.user?.arbeitnehmer?.springerKantinen?.find((k) => k.uuid === uuid);
    if (kantine) {
      setSelectedKantine(kantine);
      return;
    }

    if (auth.user?.kantine?.uuid === uuid) {
      setSelectedKantine(auth.user.kantine);
      return;
    }

    setSelectedKantine(null);
  };

  const clearCart = () => {
    for (let i = 0; i < cart.length; i++) {
      removeCartArtikel(cart[i]);
    }
  };

  return {
    addMultipleToCart,
    handleKantineChange,
    clearCart,
    detailArtikel,
    setDetailArtikel,
    cart,
    setCart,
    removeCartArtikel,
    orderdays,
    setOrderdays,
    addLineNumbers,
    addToCart,
    showSubmitSuccess,
    showSubmitError,
    cartVendors,
    loading,
    submitLoading,
    submitOrder,
    setCartVendors,
    addedToCartSuccess,
    kantinen,
    selectedKantine,
    vendors,
    vendorsLoading,
  };
};
export const ShopProvider: FC = () => {
  const shop = useShopProvider();
  const auth = useAuth();
  const sidebar = useSidebar();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const quantityRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search") || "");
    }
  }, [searchParams]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/shop?search=${search}`);
  };

  const addToCart = (artikel: VendorArtikelEntity) => {
    if (quantityRef.current) {
      const value = parseInt(quantityRef.current.value);
      shop.addToCart(artikel, value);
      shop.setDetailArtikel(null);
    }
  };

  return (
    <ShopContext.Provider value={shop}>
      <Helmet>
        <title>Shop - central</title>
      </Helmet>
      {shop.loading && (
        <div className={"w-full h-full flex flex-row justify-center items-center"}>
          <DotFlashing />
        </div>
      )}
      {!shop.loading && (
        <ShopContainerStyled useSidebar={sidebar.sidebarOpen}>
          <div
            style={{
              gridArea: "top-navigation / top-navigation / top-navigation / top-navigation",
              height: 56,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
            }}
            className={"bg-blue-800 z-10"}
          >
            <div className={"container max-w-screen-2xl h-full px-4 mx-auto w-full"}>
              <nav className={"text-white flex h-full flex-row items-center w-full gap-8"}>
                <HomeLink style={{ color: "#fff" }} to={"/"}>
                  <Logo />
                  central
                </HomeLink>
                <Link to={"/shop"} className={"text-white text-sm px-4"}>
                  Shop
                </Link>
                <div className={"grow flex flex-row justify-center gap-4"}>
                  <form onSubmit={handleSearchSubmit} className={"max-w-screen-md w-full relative grow"}>
                    <MagnifyingGlassIcon
                      className={"absolute top-1/2 left-2 transform -translate-y-1/2 w-4 h-4 text-black"}
                    />
                    <input
                      value={search}
                      onChange={handleSearchChange}
                      className={"w-full rounded-md text-black pr-3 pl-8 py-2 text-sm"}
                      placeholder={"Artikel suchen..."}
                    />
                    {search.length > 0 && (
                      <div className={"absolute top-0 right-0 h-full flex flex-row items-cente justify-center z-10"}>
                        <button
                          type={"button"}
                          className={"px-2 text-black"}
                          onClick={() => {
                            setSearch("");
                            setSearchParams({ search: "" });
                          }}
                        >
                          <XMarkIcon className={"w-4 h-4"} />
                        </button>
                      </div>
                    )}
                  </form>
                  {auth.user && auth.user.arbeitnehmer && auth.user.arbeitnehmer.springer && (
                    <select className={"text-black rounded-md px-2 text-sm"} defaultValue={auth.user.kantine?.uuid}>
                      {auth.user.kantine && <option value={auth.user.kantine.uuid}>{auth.user.kantine.name}</option>}
                      {auth.user.arbeitnehmer.supervisor &&
                        shop.kantinen.map((kantine) => (
                          <option key={kantine.uuid} value={kantine.uuid}>
                            {kantine.name}
                          </option>
                        ))}
                      {!auth.user.arbeitnehmer.supervisor &&
                        auth.user.arbeitnehmer.springerKantinen &&
                        auth.user.arbeitnehmer.springerKantinen.map((kantine) => (
                          <option key={kantine.uuid} value={kantine.uuid}>
                            {kantine.name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
                <Link
                  to={"/shop/orders?tab=offen"}
                  className={"flex flex-col items-start justify-start text-left relative"}
                >
                  <span className={"text-xs text-gray-300"}>Ihre</span>
                  <span className={"text-sm -mt-1"}>Bestellungen</span>
                  {shop.orderdays.filter((day) => !day.approved).length > 0 && (
                    <span
                      className={
                        "text-xs absolute -top-1 -right-3 text-white bg-red-500 rounded-full flex flex-row items-center justify-center w-5 h-5"
                      }
                    >
                      {shop.orderdays.filter((day) => !day.approved).length}
                    </span>
                  )}
                </Link>
                <Link to={"/shop/cart"} className={"flex flex-row items-center justify-center"}>
                  <div className={"relative"}>
                    <span
                      className={
                        "absolute bottom-1 font-bold left-1/2 transform -translate-x-1/2 text-sm text-orange-500"
                      }
                    >
                      {shop.cart.length}
                    </span>
                    <ShoppingBagIcon className={"w-10 h-10"} />
                  </div>
                  <div className={"flex flex-col items-start justify-start text-left ml-2"}>
                    <span className={"text-xs text-gray-300"}>Ihr</span>
                    <span className={"text-sm -mt-1"}>Warenkorb</span>
                  </div>
                </Link>
              </nav>
            </div>
          </div>
          <div id={"shop-wrapper"} className={"h-full max-h-full overflow-y-auto"}>
            <div className={"container max-w-screen-2xl px-4 w-full mx-auto py-8"}>
              <Suspense fallback={<LoadingPage />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </ShopContainerStyled>
      )}
      <Portal wrapperId={"shop-alerts"}>
        <AnimatePresence>
          {shop.addedToCartSuccess && (
            <SubmittedCardBox color={"white"}>Artikel erfolgreich dem Warenkorb hinzugefügt</SubmittedCardBox>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {shop.showSubmitSuccess && (
            <SubmittedCardBox color={"green"}>Bestellung erfolgreich versendet</SubmittedCardBox>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {shop.showSubmitError && (
            <SubmittedCardBox color={"red"}>Fehler beim Versenden der Bestellung</SubmittedCardBox>
          )}
        </AnimatePresence>
      </Portal>
      <Portal wrapperId={"shop-loading"}>
        <AnimatePresence>
          {shop.submitLoading && (
            <Backdrop onClose={() => {}} blur={10}>
              <div className={"w-screen h-screen flex flex-col justify-center items-center gap-4"}>
                <Eyes />
                <p>Ihre Bestellung wird verarbeitet.. einen Moment Geduld bitte</p>
                <DotFlashing />
              </div>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
      <AddToCartModal
        ref={quantityRef}
        artikel={shop.detailArtikel}
        addToCart={addToCart}
        onClose={() => shop.setDetailArtikel(null)}
      />
    </ShopContext.Provider>
  );
};

import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import Portal from "../components/Layout/Portal";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../components/Buttons/Button";
import Backdrop from "../components/Modal/Backdrop";
import styled from "@emotion/styled";

type IUseMessageProvider = {
  success: string | null;
  setSuccess: (message: string) => void;
  error: string | null;
  setError: (message: string) => void;
  errorModal: boolean;
  setErrorModal: Dispatch<SetStateAction<boolean>>;
  handleApiError: (error: any) => void;
  errorMessage: string;
  statusCode: number;
  errorTitle: string;
};

const MessageContext = createContext({} as IUseMessageProvider);

const useMessageProvider = (): IUseMessageProvider => {
  const [success, setInternalSuccess] = useState<string | null>(null);
  const [error, setInternalError] = useState<string | null>(null);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [statusCode, setStatusCode] = useState<number>(0);

  const setSuccess = (message: string) => {
    setInternalSuccess(message);
    setTimeout(() => {
      setInternalSuccess(null);
    }, 3000);
  };

  const setError = (message: string) => {
    setInternalError(message);
    setTimeout(() => {
      setInternalError(null);
    }, 3000);
  };

  const getErrorTitle = (error: any): string => {
    if (error === "unauthorized") {
      return "Fehlende Berechtigung!";
    }
    if (error && error.response && error.response.data && error.response.data.error) {
      return error.response.data.error;
    }
    return "Fehler";
  };

  const getErrorMessage = (error: any): string => {
    if (error === "unauthorized") {
      return "Sie haben keine Berechtigung für diese Aktion!";
    }
    if (error && error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    return "Unbekannter Fehler";
  };

  const getErrorCode = (error: any): number => {
    if (error === "unauthorized") {
      return 401;
    }
    if (error && error.response && error.response.data && error.response.data.statusCode) {
      return error.response.data.statusCode;
    }
    return 0;
  };

  const handleApiError = useCallback((error: any) => {
    const message = getErrorMessage(error);
    const code = getErrorCode(error);
    const title = getErrorTitle(error);
    setErrorMessage(message);
    setStatusCode(code);
    setErrorTitle(title);
    setErrorModal(true);
  }, []);

  return {
    success,
    setSuccess,
    error,
    setError,
    errorModal,
    setErrorModal,
    handleApiError,
    errorMessage,
    errorTitle,
    statusCode,
  };
};

const ErrorModal = styled(motion.div)(() => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
  width: "100%",
  maxWidth: "500px",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "20px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
}));

const ErrorMessageHeader = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: 14,
}));

const ErrorButtonWrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}));

const MessageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const messageProvider = useMessageProvider();

  return (
    <MessageContext.Provider value={messageProvider}>
      {children}
      <Portal wrapperId={"alert-portal"}>
        <AnimatePresence>
          {messageProvider.error !== null && (
            <motion.div
              initial={{ y: "150%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "150%", opacity: 0 }}
              style={{ zIndex: 1500 }}
              className={"fixed bottom-4 left-4 bg-red-500 rounded-full text-white p-4"}
            >
              {messageProvider.error}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {messageProvider.success !== null && (
            <motion.div
              initial={{ y: "150%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "150%", opacity: 0 }}
              style={{ zIndex: 1500 }}
              className={"fixed bottom-4 left-4 bg-green-500 rounded-full text-white p-4"}
            >
              {messageProvider.success}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {messageProvider.errorModal && (
            <Backdrop blur={4} onClose={() => messageProvider.setErrorModal(false)}>
              <ErrorModal
                onClick={(e) => e.stopPropagation()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <ErrorMessageHeader>
                  <span>Code {messageProvider.statusCode}</span>
                </ErrorMessageHeader>
                <p style={{ padding: "1rem 0" }}>{messageProvider.errorMessage}</p>
                <ErrorButtonWrapper>
                  <Button onClick={() => messageProvider.setErrorModal(false)} type={"button"}>
                    Schließen
                  </Button>
                </ErrorButtonWrapper>
              </ErrorModal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    </MessageContext.Provider>
  );
};

export const useMessages = () => useContext(MessageContext);
export default MessageProvider;

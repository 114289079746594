import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { IKantine } from "./useKantinen";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class Produktionsart {
  uuid?: string;
  kantinen?: IKantine[];
  name: string;
}

type IUseProduktionsarten = {
  deleteProduktionsart: (uuid: string) => Promise<void>;
  createProduktionsart: (data: Produktionsart) => Promise<Produktionsart>;
  produktionsarten: Produktionsart[];
  updateProduktionsart: (data: Produktionsart) => Promise<Produktionsart>;
  loading: boolean;
  getProduktionsart: (uuid: string) => Promise<Produktionsart>;
  READ_PRODUKTIONSARTEN: boolean;
  CREATE_PRODUKTIONSARTEN: boolean;
  UPDATE_PRODUKTIONSARTEN: boolean;
  DELETE_PRODUKTIONSARTEN: boolean;
};

type Props = {
  load?: boolean;
};

export const useProduktionsarten = (props: Props = { load: true }): IUseProduktionsarten => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [produktionsarten, setProduktionsarten] = useState<Produktionsart[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const READ_PRODUKTIONSARTEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("READ_PRODUKTIONSARTEN")),
    [auth.user]
  );
  const UPDATE_PRODUKTIONSARTEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("UPDATE_PRODUKTIONSARTEN")),
    [auth.user]
  );
  const CREATE_PRODUKTIONSARTEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("CREATE_PRODUKTIONSARTEN")),
    [auth.user]
  );
  const DELETE_PRODUKTIONSARTEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("DELETE_PRODUKTIONSARTEN")),
    [auth.user]
  );

  useEffect(() => {
    let cancel = false;

    if (READ_PRODUKTIONSARTEN) {
      if (props.load) {
        if (!cancel) {
          setLoading(true);
        }
        axios
          .get<Produktionsart[]>("/produktionsarten")
          .then((response) => {
            if (!cancel) {
              setProduktionsarten(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        if (!cancel) {
          setLoading(false);
        }
      }
    } else {
      if (!cancel) {
        setLoading(false);
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_PRODUKTIONSARTEN, handleApiError, props.load]);

  const deleteProduktionsart = (uuid: string) =>
    axios.delete("/produktionsarten/" + uuid).then(() => {
      const copy = [...produktionsarten];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === uuid) {
          copy.splice(i, 1);
          break;
        }
      }
      setProduktionsarten(copy);
    });

  const createProduktionsart = (data: Produktionsart) =>
    axios.post<Produktionsart>("/produtkionsarten", data).then((response) => {
      const copy = [...produktionsarten];
      copy.unshift(response.data);
      setProduktionsarten(copy);
      return response.data;
    });

  const updateProduktionsart = (data: Produktionsart) =>
    axios.patch<Produktionsart>("/produktionsarten", data).then((response) => {
      const copy = [...produktionsarten];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === response.data.uuid) {
          copy[i] = response.data;
          break;
        }
      }
      setProduktionsarten(copy);
      return response.data;
    });

  const getProduktionsart = (uuid: string) =>
    axios.get<Produktionsart>("/produktionsarten/" + uuid).then((response) => response.data);

  return {
    produktionsarten,
    getProduktionsart,
    loading,
    updateProduktionsart,
    deleteProduktionsart,
    createProduktionsart,
    READ_PRODUKTIONSARTEN,
    UPDATE_PRODUKTIONSARTEN,
    CREATE_PRODUKTIONSARTEN,
    DELETE_PRODUKTIONSARTEN,
  };
};

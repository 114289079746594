import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { IsString, IsUUID, ValidateIf } from "class-validator";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class IBundesland {
  @ValidateIf((o) => o.uuid !== undefined && o.uuid !== null)
  @IsUUID("4", { message: "Ungültige UUID" })
  uuid: string;

  @IsString({ message: "Kurzform muss ein String sein" })
  kurz: string;

  @IsString({ message: "Name muss ein String sein" })
  name: string;

  created: string;
  updated: string;
}

type IUseBundeslaender = {
  loading: boolean;
  bundeslaender: IBundesland[];
};

export const useBundeslaender = (): IUseBundeslaender => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [bundeslaender, setBundeslaender] = useState<IBundesland[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let cancel = false;

    if (auth.user && auth.loggedIn) {
      if (!cancel) {
        setLoading(true);
      }
      axios
        .get<IBundesland[]>("/bundeslaender")
        .then((response) => {
          if (!cancel) {
            setBundeslaender(response.data);
          }
        })
        .catch(handleApiError)
        .finally(() => {
          if (!cancel) {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }

    return () => {
      cancel = true;
    };
  }, [auth.loggedIn, auth.user, auth.canReadMandanten, handleApiError]);

  return {
    bundeslaender,
    loading,
  };
};

import React, { ChangeEvent, forwardRef, useEffect, useMemo, useState } from "react";
import { useShop } from "../../providers/ShopProvider";
import CircularProgress from "../Loading/CircularProgress";
import Portal from "../Layout/Portal";
import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "../Modal/Backdrop";
import defaultImage from "../../assets/default.jpeg";
import { formatEuro } from "../../utils/euro";
import axios from "axios";
import { VendorArtikelEntity } from "../../hooks/useVendors";

interface AddToCartModalProps {
  artikel: VendorArtikelEntity | null;
  addToCart: (artikel: VendorArtikelEntity) => void;
  onClose: () => void;
}

const AddToCartModal = forwardRef<HTMLInputElement, AddToCartModalProps>((props, ref) => {
  const shop = useShop();
  const [data, setData] = useState<string>("");
  const [value, setValue] = useState<string>("0");
  const [loading, setLoading] = useState<boolean>(false);

  const isInCart = useMemo(() => {
    if (props.artikel === null) return false;
    for (let i = 0; i < shop.cart.length; i++) {
      if (shop.cart[i].No === props.artikel.No && shop.cart[i].Vendor_No === props.artikel.Vendor_No) {
        return true;
      }
    }
    return false;
  }, [props.artikel, shop.cart]);

  useEffect(() => {
    if (props.artikel === null) return;

    let cancel = false;

    if (!cancel) {
      setLoading(true);
    }

    axios
      .get<string>("/shop/artikel/" + props.artikel.No + "/image")
      .then((res) => {
        if (!cancel) {
          if (res.data) {
            setData(res.data);
          } else {
            setData(defaultImage);
          }
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [props.artikel]);

  useEffect(() => {
    if (props.artikel === null) return;
    for (let i = 0; i < shop.cart.length; i++) {
      if (
        shop.cart[i].No === props.artikel.No &&
        shop.cart[i].Vendor_No === props.artikel.Vendor_No &&
        typeof shop.cart[i].quantity !== "undefined"
      ) {
        setValue(shop.cart[i].quantity!.toString());
        return;
      }
    }
    setValue("1");
  }, [props.artikel, shop.cart]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleRemove = () => {
    if (props.artikel === null) return;
    shop.removeCartArtikel(props.artikel);
    props.onClose();
  };

  const handleAddToCart = () => {
    if (props.artikel) {
      props.addToCart(props.artikel);
    }
  };

  return (
    <Portal wrapperId={"shop-portal"}>
      <AnimatePresence>
        {props.artikel !== null && (
          <Backdrop onClose={props.onClose}>
            <motion.div
              initial={{ scale: 0.96, x: "-50%", y: "-50%" }}
              animate={{ scale: 1, x: "-50%", y: "-50%" }}
              exit={{ scale: 0.96, x: "-50%", y: "-50%" }}
              style={{ top: "50%", left: "50%" }}
              className={"bg-white rounded-xl fixed w-full max-w-[800px] h-full max-h-[700px]"}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={"flex flex-col h-full"}>
                {!loading ? (
                  <div
                    className={"w-full min-h-[400px] h-full rounded-t-xl bg-center bg-contain bg-no-repeat"}
                    style={{ backgroundImage: "url(" + data + ")" }}
                  />
                ) : (
                  <div className={"min-h[400px] h-full flex w-full flex-col items-center justify-center"}>
                    <CircularProgress />
                  </div>
                )}
                <div className={"p-4 mt-auto"}>
                  <h2 className={"font-bold text-xl pt-2"}>{props.artikel.Description}</h2>
                  <p className={"text-xs text-gray-500"}>Art.Nr.: {props.artikel.Vendor_Item_No}</p>
                  <p>{props.artikel.Description2}</p>
                  <p className={"font-light"}>{props.artikel.AMA_VendorName}</p>
                  <div className={"flex flex-row w-full pt-6 gap-4"}>
                    {isInCart && (
                      <button className={"rounded-xl bg-red-500 text-white py-2 px-3"} onClick={handleRemove}>
                        Aus dem Warenkorb entfernen
                      </button>
                    )}
                    <div className={"grow"} />
                    <div className={"flex flex-row flex-nowrap justify-center items-center px-2"}>
                      a {formatEuro(props.artikel.Last_Direct_Cost)}
                    </div>
                    <input
                      value={value}
                      onChange={onChange}
                      className={"py-2 px-3 text-sm min-w-[100px] rounded-xl border-1 border-solid border-gray-200"}
                      type={"number"}
                      autoComplete={"off"}
                      autoCapitalize={"none"}
                      autoCorrect={"off"}
                      spellCheck={"false"}
                      min={1}
                      max={100}
                      ref={ref}
                    />
                    <div className={"flex text-sm font-extrabold flex-row justify-center items-center"}>
                      {props.artikel.Base_Unit_of_Measure}
                    </div>
                    <button className={"rounded-xl bg-gray-900 text-white py-2 px-3"} onClick={handleAddToCart}>
                      {isInCart ? "Menge aktualisieren" : "In den Warenkorb"}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
});

export default AddToCartModal;

import React, { createContext, useContext, useEffect, useState, useMemo, FC, ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import CircularProgress from "../components/Loading/CircularProgress";
import { useMessages } from "./MessageProvider";
import { IVersandetikett } from "../hooks/useEtiketten";
import { IKantine } from "../hooks/useKantinen";
import { IArbeitnehmer } from "../hooks/useArbeitnehmer";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export interface Ausgabeliste {
  name: string;
  base64: string;
}

export interface NavigationElement {
  uuid: string;
  to: string;
  text: string;
  subgroup: string | null;
  presentationOrder?: number;
  required: string;
  tab?: string | null;
}

export interface Ferientag {
  id: string;
  datum: string;
  created: string;
  updated: string;
}

export interface Feiertag {
  id: string;
  datum: string;
  created: string;
  updated: string;
}

export interface NavigationElementGroup {
  uuid: string;
  name: string;
  presentationOrder?: number;
  to: string;
  elements: NavigationElement[];
  required: string;
  hideNavbar: boolean;
}

export interface User {
  username: string;
  itemsPerPage: number;
  email: string;
  uuid: string;
  vorname: string;
  nachname: string;
  permissions: string[];
  role: string;
  arbeitnehmer: IArbeitnehmer | null;
  navigation: NavigationElementGroup[];
  kantine: IKantine | null;
  versandetiketten: IVersandetikett[];
  firstLogin: boolean;
}

interface IUseAuthProvider {
  login: (username: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
  setUser: (user: User) => void;
  loggedIn: boolean;
  user: User | null;
  loggingOut: boolean;
  validating: boolean;
  READ_ABRECHNUNG: boolean;
  canReadAbteilungen: boolean;
  CREATE_ABTEILUNGEN: boolean;
  canUpdateAbteilung: boolean;
  canDeleteAbteilung: boolean;
  READ_URLAUBSMANAGER: boolean;
  READ_AUSGABELISTEN: boolean;
  canReadEssenausgabekonzepte: boolean;
  canReadBestellsysteme: boolean;
  canReadTouren: boolean;
  canReadSchaupp: boolean;
  CREATE_ARTIKEL_CATEGORIES: boolean;
  canReadKitas: boolean;
  canReadMandanten: boolean;
  canReadFinanzen: boolean;
  canReadManagement: boolean;
  READ_FORMULARS: boolean;
  CREATE_DISPLAY_MEALS: boolean;
  UPDATE_DISPLAY_MEALS: boolean;
  DELETE_DISPLAY_MEALS: boolean;
  READ_ARTIKEL_CATEGORIES: boolean;
  READ_SYSTEM_BACKUP: boolean;
  itemsPerPage: number;
  handleItemsPerPageChange: (newPage: number) => void;
  handleItemsPerPageSave: (newValue: number) => void;
  ausgabelisten: Ausgabeliste[];
  urlaubsmanagerMonate: UrlaubsmanagerMonat[];
}

interface LoginResponse extends User {
  token: string;
}

const LoadingWrapper = styled("div")(() => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

type UrlaubsmanagerMonat = {
  monat: string;
  krank: number;
  urlaub: number;
};

type Error = AxiosError & { config: AxiosRequestConfig & { _retry?: boolean } };

axios.interceptors.response.use();

let Interceptor: any | null = null;

export const AuthContext = createContext({} as IUseAuthProvider);

const useAuthProvider = (): IUseAuthProvider => {
  const alert = useMessages();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [validating, setValidating] = useState<boolean>(true);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [ausgabelisten, setAusgabelisten] = useState<Ausgabeliste[]>([]);
  const [urlaubsmanagerMonate, setUrlaubsmanagerMonate] = useState<UrlaubsmanagerMonat[]>([]);

  const READ_ABRECHNUNG = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_ABRECHNUNG");
  }, [user]);

  const canReadAbteilungen = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_ABTEILUNGEN");
  }, [user]);

  const CREATE_ABTEILUNGEN = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("CREATE_ABTEILUNGEN");
  }, [user]);

  const canUpdateAbteilung = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("UPDATE_ABTEILUNGEN");
  }, [user]);

  const canDeleteAbteilung = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("DELETE_ABTEILUNGEN");
  }, [user]);

  const READ_URLAUBSMANAGER = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_URLAUBSMANAGER");
  }, [user]);

  const READ_AUSGABELISTEN = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_AUSGABELISTEN");
  }, [user]);

  const canReadEssenausgabekonzepte = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_ESSENAUSGABEKONZEPTE");
  }, [user]);

  const canReadBestellsysteme = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_BESTELLSYSTEME");
  }, [user]);

  const canReadTouren = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_TOUREN");
  }, [user]);

  const canReadSchaupp = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_SCHAUPP");
  }, [user]);

  const canReadMandanten = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_MANDANTEN");
  }, [user]);

  const canReadKitas = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_KITA");
  }, [user]);

  const canReadFinanzen = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_FINANZEN");
  }, [user]);

  const canReadManagement = useMemo(() => {
    if (!user) return false;
    return user.permissions.includes("READ_MANAGEMENT");
  }, [user]);

  const READ_FORMULARS = useMemo(() => Boolean(user?.permissions.includes("READ_FORMULARS")), [user]);

  const CREATE_DISPLAY_MEALS = useMemo(() => Boolean(user?.permissions.includes("CREATE_DISPLAY_MEALS")), [user]);

  const UPDATE_DISPLAY_MEALS = useMemo(() => Boolean(user?.permissions.includes("UPDATE_DISPLAY_MEALS")), [user]);

  const DELETE_DISPLAY_MEALS = useMemo(() => Boolean(user?.permissions.includes("DELETE_DISPLAY_MEALS")), [user]);

  const READ_ARTIKEL_CATEGORIES = useMemo(() => Boolean(user?.permissions.includes("READ_ARTIKEL_CATEGORIES")), [user]);

  const CREATE_ARTIKEL_CATEGORIES = useMemo(
    () => Boolean(user?.permissions.includes("CREATE_ARTIKEL_CATEGORIES")),
    [user]
  );

  useEffect(() => {
    if (user && loggedIn) {
      setItemsPerPage(user.itemsPerPage);
    }
  }, [loggedIn, user]);

  useEffect(() => {
    let cancel = false;

    if (READ_AUSGABELISTEN) {
      axios.get<Ausgabeliste[]>("/ausgabelisten").then((response) => {
        if (!cancel) {
          setAusgabelisten(response.data);
        }
      });
    } else {
      if (!cancel) {
        setAusgabelisten([]);
      }
    }

    if (READ_URLAUBSMANAGER) {
      axios.get<UrlaubsmanagerMonat[]>("/urlaubsmanager/statistik").then((response) => {
        if (!cancel) {
          setUrlaubsmanagerMonate(response.data);
        }
      });
    } else {
      if (!cancel) {
        setUrlaubsmanagerMonate([]);
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_AUSGABELISTEN, READ_URLAUBSMANAGER]);

  useEffect(() => {
    let cancel = false;

    const token = localStorage.getItem("token");

    if (!token || typeof token === "undefined" || String(token).length <= 0) {
      localStorage.removeItem("token");
      setUser(null);
      setLoggedIn(false);
      setValidating(false);
      localStorage.setItem("previousPath", location.pathname);
      return;
    }

    if (!user) {
      axios
        .get<User>("/auth/user")
        .then((res) => {
          if (!cancel) {
            setUser(res.data);
            setLoggedIn(true);
          }
        })
        .catch(() => {
          setLoggedIn(false);
          localStorage.setItem("previousPath", location.pathname);
        })
        .finally(() => {
          if (!cancel) {
            setValidating(false);
          }
        });
    } else {
      if (!cancel) {
        setValidating(false);
      }
    }

    return () => {
      cancel = true;
    };
  }, [location.pathname, user]);

  const login = async (username: string, password: string): Promise<string> => {
    setValidating(true);
    return axios
      .post<LoginResponse>("/auth/login", { username, password })
      .then((response) => {
        const { token, ...rest } = response.data;
        localStorage.setItem("token", token);
        const user: User = rest;
        setUser(user);
        setLoggedIn(true);
        Interceptor = axios.interceptors.response.use(
          (response) => response,
          async (error: Error) => {
            if (error && error.config && error.response) {
              const originalRequest = error.config;
              if (error.config.url !== "/auth/login" && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const tokens = await axios
                  .post<string>("/auth/refresh", {}, { withCredentials: true })
                  .then((res) => res.data);
                localStorage.setItem("token", tokens);
                return axios(originalRequest);
              }
            }
            return Promise.reject(error);
          }
        );
        if (user.firstLogin) return "/set-password";
        const previousPath = localStorage.getItem("previousPath");
        if (previousPath) {
          localStorage.removeItem("previousPath");
          return previousPath;
        }
        return "/";
      })
      .catch((error) => {
        setLoggedIn(false);
        if (error.response.status === 401) {
          throw new Error("Benutzername oder Passwort falsch");
        }
        throw new Error("Login fehlgeschlagen");
      })
      .finally(() => {
        setValidating(false);
      });
  };

  const logout = () => {
    setLoggingOut(true);
    return axios
      .post<void>("/auth/logout")
      .then(() => {
        localStorage.removeItem("token");
        setUser(null);
        setLoggedIn(false);
        if (Interceptor) {
          axios.interceptors.response.eject(Interceptor);
        }
      })
      .finally(() => setLoggingOut(false));
  };

  const handleItemsPerPageSave = (newItems: number) => {
    if (!user || !loggedIn) {
      return;
    }
    if (user.itemsPerPage !== newItems) {
      axios
        .post("/auth/items-per-page", { itemsPerPage: newItems })
        .then(() => {
          alert.setSuccess("Einstellungen gespeichert");
          setItemsPerPage(newItems);
          if (user && loggedIn) {
            setUser({ ...user, itemsPerPage: newItems });
          }
        })
        .catch(() => {
          alert.setError("Einstellungen konnten nicht gespeichert werden");
        });
    }
  };

  const handleItemsPerPageChange = (newItems: number) => {
    setItemsPerPage(newItems);
  };

  const READ_SYSTEM_BACKUP = useMemo(() => Boolean(user?.permissions.includes("READ_SYSTEM_BACKUP")), [user]);

  return {
    itemsPerPage,
    READ_SYSTEM_BACKUP,
    handleItemsPerPageSave,
    handleItemsPerPageChange,
    CREATE_ARTIKEL_CATEGORIES,
    READ_ARTIKEL_CATEGORIES,
    DELETE_DISPLAY_MEALS,
    UPDATE_DISPLAY_MEALS,
    CREATE_DISPLAY_MEALS,
    loggingOut,
    loggedIn,
    validating,
    user,
    setUser,
    login,
    logout,
    canReadManagement,
    canReadFinanzen,
    canReadKitas,
    canReadMandanten,
    canReadSchaupp,
    canReadTouren,
    canReadBestellsysteme,
    canReadEssenausgabekonzepte,
    READ_FORMULARS,
    READ_ABRECHNUNG,
    canReadAbteilungen,
    CREATE_ABTEILUNGEN,
    canUpdateAbteilung,
    canDeleteAbteilung,
    READ_URLAUBSMANAGER,
    READ_AUSGABELISTEN,
    ausgabelisten,
    urlaubsmanagerMonate,
  };
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useAuthProvider();

  if (auth.validating || auth.loggingOut) return <LoadingPage />;
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const LoadingPage: React.FC = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
);

export const PrivateRoute: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.loggedIn) return <Navigate to={"/login"} />;

  if (auth.user) {
    if (auth.user.firstLogin) {
      if (location.pathname !== "/set-password") {
        return <Navigate to={"/set-password"} />;
      }
    }
    if (location.pathname.startsWith("/formulars") && auth.READ_FORMULARS) {
      return <Outlet />;
    }
    switch (location.pathname) {
      case "/":
      case "/faq":
      case "/support":
      case "/konto":
      case "/ticket": {
        return <Outlet />;
      }
      case "/documents": {
        if (Boolean(auth.user.permissions.includes("READ_DOCUMENTS"))) return <Outlet />;
        return <Navigate to={"/"} />;
      }
      case "/set-password": {
        if (auth.user.firstLogin) {
          return <Outlet />;
        } else {
          return <Navigate to={"/"} />;
        }
      }
      default: {
        let canOpenRoute = false;
        for (const group of auth.user.navigation) {
          if (!auth.user.permissions.find((perm) => perm === group.required)) {
            break;
          } else if (location.pathname.startsWith(group.to)) {
            if (location.pathname === group.to) {
              canOpenRoute = true;
              break;
            }
            if (location.pathname.startsWith("/shop")) {
              canOpenRoute = true;
              break;
            }
            for (const item of group.elements) {
              if (location.pathname.startsWith(group.to + item.to)) {
                canOpenRoute = true;
                break;
              }
            }
            break;
          }
        }
        if (!canOpenRoute) {
          return <Navigate to={"/"} />;
        }
      }
    }
    return <Outlet />;
  }
  return <Navigate to={"/login"} />;
};

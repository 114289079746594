import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { IsNotEmpty, IsOptional, IsString, IsUUID } from "class-validator";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class IBestellsystem {
  @IsOptional()
  @IsUUID()
  uuid?: string;

  @IsNotEmpty({ message: "Name muss angegeben werden" })
  @IsString({ message: "Name muss eine Texteingabe sein" })
  name: string;

  created?: string;
  updated?: string;
}

type UseBestellsystem = {
  bestellsysteme: IBestellsystem[];
  updateBestellsystem: (data: IBestellsystem) => Promise<IBestellsystem>;
  createBestellsystem: (data: IBestellsystem) => Promise<IBestellsystem>;
  loading: boolean;
};

type Props = {
  loadBestellsysteme?: boolean;
};

export const useBestellsysteme = (props: Props = { loadBestellsysteme: true }): UseBestellsystem => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [bestellsysteme, setBestellsysteme] = useState<IBestellsystem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let cancel = false;

    if (auth.canReadBestellsysteme) {
      if (props.loadBestellsysteme) {
        if (!cancel) {
          setLoading(true);
        }

        axios
          .get<IBestellsystem[]>("/bestellsysteme")
          .then((response) => {
            if (!cancel) {
              setBestellsysteme(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    return () => {
      cancel = true;
    };
  }, [auth.canReadBestellsysteme, handleApiError, props.loadBestellsysteme]);

  const updateBestellsystem = (data: IBestellsystem) =>
    axios.patch<IBestellsystem>("/bestellsysteme", data).then((response) => {
      const copy = [...bestellsysteme];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === response.data.uuid) {
          copy[i] = response.data;
          break;
        }
      }
      setBestellsysteme(copy);
      return response.data;
    });

  const createBestellsystem = (data: IBestellsystem) =>
    axios.post<IBestellsystem>("/bestellsysteme", data).then((response) => {
      const copy = [...bestellsysteme];
      copy.unshift(response.data);
      setBestellsysteme(copy);
      return response.data;
    });

  return {
    bestellsysteme,
    loading,
    updateBestellsystem,
    createBestellsystem,
  };
};

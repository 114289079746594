import { FC, useEffect } from "react";
import styled from "@emotion/styled";

const Eye = styled("div")(() => ({
  position: "relative",
  display: "inline-block",
  borderRadius: "50%",
  height: 30,
  width: 30,
  backgroundColor: "#c9c9c9",
  "&:after": {
    position: "absolute",
    bottom: 17,
    right: 10,
    width: 10,
    height: 10,
    backgroundColor: "#000",
    borderRadius: "50%",
    content: "''",
  },
}));

const Eyes: FC = () => {
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const eyes = document.getElementsByClassName("eye");
      for (let i = 0; i < eyes.length; i++) {
        const eye = eyes[i] as HTMLElement;
        const x = eye.getBoundingClientRect().left + eye.offsetWidth / 2;
        const y = eye.getBoundingClientRect().top + eye.offsetHeight / 2;
        const rad = Math.atan2(event.pageX - x, event.pageY - y);
        const rot = rad * (180 / Math.PI) * -1 + 180;
        eye.style.transform = "rotate(" + rot + "deg)";
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  });

  return (
    <div className={"flex flex-row flex-nowrap"}>
      <Eye className={"eye"} />
      <Eye className={"eye"} />
    </div>
  );
};

export default Eyes;

import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { IDisplayGroup } from "./useDisplayGroups";
import axios, { AxiosResponse } from "axios";
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  IsUUID,
  Matches,
  MaxLength,
  MinLength,
  ValidateIf,
  ValidateNested,
} from "class-validator";
import { Display } from "./useDisplays";
import { Type } from "class-transformer";
import { IBundesland } from "./useBundeslaender";
import { Kundengruppe } from "./useKundengruppen";
import { Essenausgabekonzept } from "./useEssenausgabeKonzepte";
import { IBestellsystem } from "./useBestellsystem";
import { Tour } from "./useTouren";
import { Zuschussart } from "./useZuschussarten";
import { IDrucker } from "./useDrucker";
import { ZentralkuecheEntity } from "./useZentralkuechen";
import { Produktionsart } from "./useProduktionsarten";
import { IArbeitnehmer } from "./useArbeitnehmer";
import { Produktionsort } from "./useProduktionsorte";
import { VendorEntity } from "./useVendors";

export class IKantine {
  @IsOptional()
  @IsUUID()
  uuid: string;

  shopVendors: VendorEntity[];

  @IsString()
  @MinLength(1, { message: "Name muss mindestens 1 Zeichen lang sein" })
  @MaxLength(100, { message: "Name darf nicht länger als 100 Zeichen sein" })
  name: string;

  displays: Display[];

  @IsOptional()
  @IsString()
  strasse: string;

  @IsOptional()
  @IsString()
  navision_lagerort_code: string;

  @IsOptional()
  @IsString()
  hausnummer: string;

  @IsOptional()
  @IsString()
  ansprechpartner: string;
  created: string;

  email: string;

  @IsNotEmpty({ message: "Ort muss angegeben werden" })
  @IsString({ message: "Ort muss ein String sein" })
  @MinLength(1, { message: "Ort muss mindestens 1 Zeichen lang sein" })
  ort: string;

  @IsNotEmpty({ message: "PLZ muss angegeben werden" })
  @IsString({ message: "PLZ muss ein String sein" })
  @MinLength(4, { message: "PLZ muss mindestens 4 Zeichen lang sein" })
  plz: string;

  @ValidateIf((o) => o.bundesland !== undefined)
  @IsNotEmpty({ message: "Bundesland muss angegeben werden" })
  @IsObject({ message: "Bundesland muss ein Objekt sein" })
  @Type(() => IBundesland)
  @ValidateNested({ message: "Bundesland parameter fehlen" })
  bundesland: IBundesland | null;

  @ValidateIf((o) => o.kundengruppe !== undefined)
  @IsNotEmpty({ message: "Kundengruppe muss angegeben werden" })
  @IsObject({ message: "Kundengruppe muss ein Objekt sein" })
  @Type(() => Kundengruppe)
  @ValidateNested({ message: "Kundengruppe parameter fehlen" })
  kundengruppe: Kundengruppe | null;

  @IsOptional()
  @IsObject()
  @Type(() => Produktionsort)
  produktionsort: Produktionsort | null;

  anzahl_menus: number | null;

  @IsOptional()
  @IsNumber({ maxDecimalPlaces: 0 }, { message: "Anzahl Essenmenge muss eine ganze Zahl sein" })
  essenmenge: number | null;

  @IsOptional()
  @IsNumber({ maxDecimalPlaces: 2 }, { message: "Preis muss eine Zahl sein" })
  preis: number | null;

  @IsOptional()
  @IsString({ message: "Preis muss ein String sein" })
  preis_infos: string | null;

  @ValidateIf((o) => o.vorbestellung !== undefined && o.vorbestellung !== null)
  @IsBoolean({ message: "Vorbestellung muss ein Boolean sein" })
  vorbestellung: boolean;

  @ValidateIf((o) => o.wochenendversorgung !== undefined && o.wochenendversorgung !== null)
  @IsBoolean({ message: "Wochenendversorgung muss ein Boolean sein" })
  wochenendversorgung: boolean;

  @ValidateIf((o) => o.ausgabe_ab !== undefined && o.ausgabe_ab !== null)
  @Matches(RegExp("[0-9]{2}:[0-9]{2}"), { message: "Essenausgabe ab muss ein Zeitformat sein" })
  ausgabe_ab: string | null;

  @IsOptional()
  @IsOptional()
  @Type(() => Essenausgabekonzept)
  essenausgabe_konzept: Essenausgabekonzept | null;

  @ValidateIf((o) => o.cafeteria_zusatzverkauf !== undefined && o.cafeteria_zusatzverkauf !== null)
  @IsOptional()
  @IsBoolean({ message: "Cafeteria Zusatzverkauf muss ein Boolean sein" })
  cafeteria_zusatzverkauf: boolean;

  @ValidateIf((object) => typeof object.preis_fruehstueck === "string" && object.preis_fruehstueck.length > 0)
  @IsString({ message: "Preis Frühstück muss ein String sein" })
  @MinLength(1, { message: "Preis Frühstück muss mindestens 1 Zeichen lang sein" })
  @MaxLength(20, { message: "Preis Frühstück darf maximal 20 Zeichen lang sein" })
  preis_fruehstueck: string | null;

  @ValidateIf((object) => typeof object.preis_fruehstueck === "string" && object.preis_fruehstueck.length > 0)
  @IsString({ message: "Preis Vesper muss ein String sein" })
  @MinLength(1, { message: "Preis Vesper muss mindestens 1 Zeichen lang sein" })
  @MaxLength(20, { message: "Preis Vesper darf maximal 20 Zeichen lang sein" })
  preis_vesper: string | null;

  @IsOptional()
  @IsString({ message: "Zusatzinfos muss ein String sein" })
  fruehstueck_vesper_zusatzinfos: string;

  @IsOptional()
  @IsObject()
  @Type(() => IBestellsystem)
  bestellsystem: IBestellsystem | null;

  @IsOptional()
  @IsObject()
  @Type(() => Produktionsart)
  produktionsart: Produktionsart | null;

  @IsOptional()
  @IsString({ message: "Verpackungshinweis muss ein String sein" })
  verpackungshinweise: string | null;

  @ValidateIf((o) => o.bereitstellung_fuhrpark_bis !== undefined && o.bereitstellung_fuhrpark_bis !== null)
  @Matches(RegExp("[0-9]{2}:[0-9]{2}"), { message: "Bereitstellung Fuhrpark bis muss ein Zeitformat sein" })
  bereitstellung_fuhrpark_bis: string | null;

  shuttle_fuhrpark: string | null;

  @IsOptional()
  @IsBoolean({ message: "Alarmanlage muss ein Boolean sein" })
  alarmanlage_kueche: boolean;

  @ValidateIf((o) => o.anlieferung_ab !== undefined && o.anlieferung_ab !== null)
  @Matches(RegExp("[0-9]{2}:[0-9]{2}"), { message: "Anlieferung ab muss ein Zeitformat sein" })
  anlieferung_ab: string | null;

  @ValidateIf((o) => o.anlieferung_bis !== undefined && o.anlieferung_bis !== null)
  @Matches(RegExp("[0-9]{2}:[0-9]{2}"), { message: "Anlieferung bis muss ein Zeitformat sein" })
  anlieferung_bis: string | null;

  @IsOptional()
  @IsBoolean({ message: "Ebenerdige Anlieferung muss ein Boolean sein" })
  ebenerdige_anlieferung: boolean;

  fahrstuhl_vorhanden: boolean;
  biotonnen_pro_tag: boolean;
  schluessel_fuhrpark: boolean;
  schluessel_fuhrpark_infos: string | null;
  tour_zustellung: Tour | null;
  tour_schule: Tour | null;
  tour_menuservice: Tour | null;
  google_maps_link: string;
  supervisor: IArbeitnehmer | null;
  telefon_hausmeister: string | null;
  telefon_standort: string | null;
  start: string | null;
  start_standort: string | null;

  @IsOptional()
  @IsBoolean()
  konferenzversorgung: boolean;

  @IsOptional()
  @IsObject()
  @Type(() => Zuschussart)
  zuschussart: Zuschussart | null;

  zuschusshoehe: string | null;
  brutto_netto: "brutto" | "netto" | null;
  monatliche_pauschalen: number;
  betriebskosten: string | null;

  @IsOptional()
  @IsBoolean({ message: "Bitte wählen Sie eine Option aus" })
  internet: boolean;

  listen: null | string[];
  updated: string;
  printer: IDrucker[];

  @IsOptional()
  @IsObject()
  @Type(() => IDisplayGroup)
  @ValidateNested({ each: true })
  artikelGruppe: IDisplayGroup | null;

  @IsOptional()
  @IsObject()
  @Type(() => IDisplayGroup)
  @ValidateNested({ each: true })
  mealGruppe: IDisplayGroup | null;
}

type IUseKantinen = {
  kantinen: IKantine[];
  loading: boolean;
  createKantine: (kantine: IKantine) => Promise<IKantine>;
  updateKantine: (kantine: IKantine) => Promise<IKantine>;
  deleteKantine: (uuid: string) => Promise<void>;
  getKantine: (uuid: string) => Promise<IKantine>;
  setKantinenVendor: (vendor: VendorEntity, kantine: IKantine) => Promise<VendorEntity>;
  updateKantinMealGroup: (kantine: IKantine, group: IDisplayGroup) => void;
  updateKantinArtikelGroup: (kantine: IKantine, group: IDisplayGroup) => void;
  removeKantineArtikelGroup: (kantine: IKantine) => void;
  removeKantineMealGroup: (kantine: IKantine) => void;
  setVendorZentralkueche: (vendor: VendorEntity, kueche: ZentralkuecheEntity | null) => Promise<VendorEntity>;
  removeVendorFromKantine: (uuid: string, vendor: VendorEntity) => Promise<AxiosResponse<void>>;
  DELETE_KANTINEN: boolean;
  READ_KANTINEN: boolean;
  UPDATE_KANTINEN: boolean;
  CREATE_KANTINEN: boolean;
};

type IUseKantinenProps = {
  loadAll: boolean;
};

export const useKantinen = ({ loadAll }: IUseKantinenProps = { loadAll: true }): IUseKantinen => {
  const auth = useAuth();
  const [kantinen, setKantinen] = useState<IKantine[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const DELETE_KANTINEN = useMemo(() => {
    if (!auth.user) return false;
    return Boolean(auth.user.permissions.find((permission) => permission === "DELETE_KANTINEN"));
  }, [auth.user]);

  const READ_KANTINEN = useMemo(() => {
    if (!auth.user) return false;
    return Boolean(auth.user.permissions.find((permission) => permission === "READ_KANTINEN"));
  }, [auth.user]);

  const CREATE_KANTINEN = useMemo(() => {
    if (!auth.user) return false;
    return Boolean(auth.user.permissions.find((permission) => permission === "CREATE_KANTINEN"));
  }, [auth.user]);

  const UPDATE_KANTINEN = useMemo(() => {
    if (!auth.user) return false;
    return Boolean(auth.user.permissions.find((permission) => permission === "UPDATE_KANTINEN"));
  }, [auth.user]);

  useEffect(() => {
    let cancel = false;

    if (auth.loggedIn && READ_KANTINEN && loadAll) {
      setLoading(true);
      axios
        .get<IKantine[]>("/kantinen")
        .then((response) => {
          if (!cancel) {
            setKantinen(response.data);
          }
        })
        .finally(() => {
          if (!cancel) {
            setLoading(false);
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [READ_KANTINEN, auth.loggedIn, loadAll]);

  const createKantine = (data: IKantine): Promise<IKantine> => {
    if (CREATE_KANTINEN) {
      return axios.post<IKantine>("/kantinen", data).then((response) => {
        const copy = [...kantinen];
        copy.unshift(response.data);
        setKantinen(copy);
        return response.data;
      });
    }
    return Promise.reject("unauthorized");
  };

  const getKantine = (uuid: string): Promise<IKantine> => {
    if (READ_KANTINEN) {
      return axios.get<IKantine>("/kantinen/" + uuid).then((response) => response.data);
    }
    return Promise.reject("unauthorized");
  };

  const deleteKantine = (uuid: string): Promise<void> => {
    if (DELETE_KANTINEN) {
      return axios.delete("/kantinen/" + uuid).then(() => {
        const copy = [...kantinen];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === uuid) {
            copy.splice(i, 1);
            break;
          }
        }
        setKantinen(copy);
      });
    }
    return Promise.reject("unauthorized");
  };

  const updateKantine = (data: IKantine) => {
    if (UPDATE_KANTINEN) {
      return axios.patch<IKantine>("/kantinen", data).then((response) => {
        const copy = [...kantinen];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === response.data.uuid) {
            copy[i] = response.data;
            break;
          }
        }
        setKantinen(copy);
        return response.data;
      });
    }
    return Promise.reject("unauthorized");
  };

  const setKantinenVendor = (vendor: VendorEntity, kantine: IKantine): Promise<VendorEntity> => {
    if (UPDATE_KANTINEN) {
      return axios.post<VendorEntity>("/kantinen/vendor/" + kantine.uuid, vendor).then((response) => {
        const copy = [...kantinen];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === kantine.uuid) {
            copy[i].shopVendors.push(response.data);
            break;
          }
        }

        setKantinen(copy);

        return response.data;
      });
    }
    return Promise.reject("unauthorized");
  };

  const updateKantinArtikelGroup = (kantine: IKantine, group: IDisplayGroup) => {
    const copy = [...kantinen];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].uuid === kantine.uuid) {
        copy[i].artikelGruppe = group;
        break;
      }
    }
    setKantinen(copy);
  };

  const updateKantinMealGroup = (kantine: IKantine, group: IDisplayGroup) => {
    const copy = [...kantinen];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].uuid === kantine.uuid) {
        copy[i].mealGruppe = group;
        break;
      }
    }
    setKantinen(copy);
  };

  const removeKantineArtikelGroup = (kantine: IKantine) => {
    const copy = [...kantinen];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].uuid === kantine.uuid) {
        copy[i].artikelGruppe = null;
        break;
      }
    }
    setKantinen(copy);
  };

  const removeKantineMealGroup = (kantine: IKantine) => {
    const copy = [...kantinen];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].uuid === kantine.uuid) {
        copy[i].mealGruppe = null;
        break;
      }
    }
    setKantinen(copy);
  };

  const setVendorZentralkueche = (vendor: VendorEntity, kueche: ZentralkuecheEntity | null): Promise<VendorEntity> =>
    axios.patch<VendorEntity>("/shop/vendor", { vendor, kueche }).then((response) => response.data);

  const removeVendorFromKantine = (uuid: string, vendor: VendorEntity) =>
    axios.delete<void>("/kantinen/" + uuid + "/vendor/" + vendor.uuid);

  return {
    removeVendorFromKantine,
    setVendorZentralkueche,
    updateKantinMealGroup,
    updateKantinArtikelGroup,
    removeKantineArtikelGroup,
    removeKantineMealGroup,
    setKantinenVendor,
    kantinen,
    loading,
    updateKantine,
    deleteKantine,
    createKantine,
    getKantine,
    DELETE_KANTINEN,
    READ_KANTINEN,
    CREATE_KANTINEN,
    UPDATE_KANTINEN,
  };
};

import { FC, lazy } from "react";
import { Navigate, Outlet, Route, Routes as BrowserRoutes } from "react-router-dom";
import { PrivateRoute } from "./providers/AuthProvider";
import { ShopProvider } from "./providers/ShopProvider";

// DEFAULT
const NotFound = lazy(() => import("./pages/NotFound"));
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const Logout = lazy(() => import("./pages/Logout"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Konto = lazy(() => import("./pages/Konto"));
const Ticket = lazy(() => import("./pages/Ticket"));
const PublicDocuments = lazy(() => import("./pages/Documents"));

// FORMULARE
const Formulars = lazy(() => import("./pages/Formulars"));
const Formular = lazy(() => import("./pages/Formular"));

// MANAGEMENT
const ShopOrders = lazy(() => import("./pages/Management/Shop/Orders"));
const ShopVendors = lazy(() => import("./pages/Management/Shop/Vendors"));
const Bewertungen = lazy(() => import("./pages/Management/Bewertungen"));
const Documents = lazy(() => import("./pages/Management/Documents"));
const ApiShop = lazy(() => import("./pages/Management/ApiShop"));
const ApiBenutzer = lazy(() => import("./pages/Management/ApiBenutzer"));
const Arbeitnehmer = lazy(() => import("./pages/Management/Arbeitnehmer"));
const Abwesenheitspraemie = lazy(() => import("./pages/Management/Abwesenheitspraemie"));
const DetailArbeitnehmer = lazy(() => import("./pages/Management/DetailArbeitnehmer"));
const Abteilungen = lazy(() => import("./pages/Management/Abteilungen"));
const DetailAbteilung = lazy(() => import("./pages/Management/DetailAbteilung"));
const Arbeitsvertraege = lazy(() => import("./pages/Management/Arbeitsvertraege"));
const DetailArbeitsvertrag = lazy(() => import("./pages/Management/DetailArbeitsvertrag"));
const Urlaubsmanager = lazy(() => import("./pages/Management/Urlaubsmanager"));
const Abteilungsansicht = lazy(() => import("./pages/Management/Abteilungsansicht"));
const Gesundheit = lazy(() => import("./pages/Management/Gesundheit"));

// DIGITAL SIGNAGE
const DetailArtikel = lazy(() => import("./pages/DigitalSignage/DetailArtikel"));
const Artikel = lazy(() => import("./pages/DigitalSignage/Artikel"));
const Displays = lazy(() => import("./pages/DigitalSignage/Displays"));
const DetailDisplay = lazy(() => import("./pages/DigitalSignage/DetailDisplay"));
const DisplayGruppen = lazy(() => import("./pages/DigitalSignage/DisplayGruppen"));
const DetailGruppe = lazy(() => import("./pages/DigitalSignage/DetailGruppe"));
const KronospanDisplays = lazy(() => import("./pages/DigitalSignage/KronospanDisplays"));
const KronospanUsers = lazy(() => import("./pages/DigitalSignage/KronospanUsers"));

// FINANZEN
const Abrechnung = lazy(() => import("./pages/Finanzen/Abrechnung"));
const Ueberstunden = lazy(() => import("./pages/Finanzen/Ueberstunden"));
const Minijob = lazy(() => import("./pages/Finanzen/Minijob"));
const Mehrarbeit = lazy(() => import("./pages/Finanzen/Mehrarbeit"));
const Unternehmen = lazy(() => import("./pages/Finanzen/Unternehmen"));
const Kostenstellen = lazy(() => import("./pages/Finanzen/Kostenstellen"));
const Schaupp = lazy(() => import("./pages/Finanzen/Schaupp"));
const SchauppDetail = lazy(() => import("./pages/Finanzen/SchauppDetail"));
const SchauppArchiviert = lazy(() => import("./pages/Finanzen/SchauppArchiviert"));
const UeberstundenAnfragen = lazy(() => import("./pages/Finanzen/UeberstundenAnfragen"));
const MultiMehrarbeit = lazy(() => import("./pages/Finanzen/MultiMehrarbeit"));
const MultiMinijob = lazy(() => import("./pages/Finanzen/MultiMinijob"));

// SHOP
const Shop = lazy(() => import("./pages/Shop"));
const Orders = lazy(() => import("./pages/Shop/Orders"));
const Order = lazy(() => import("./pages/Shop/Order"));
const Cart = lazy(() => import("./pages/Shop/Cart"));

// KITA
const Kitas = lazy(() => import("./pages/Kita/Kitas"));
const Kita = lazy(() => import("./pages/Kita"));
const KitaBestellung = lazy(() => import("./pages/Kita/KitaBestellung"));

// SYSTEM
const Backup = lazy(() => import("./pages/System/Backup"));
const System = lazy(() => import("./pages/System"));
const Benutzer = lazy(() => import("./pages/System/Benutzer"));
const DetailBenutzer = lazy(() => import("./pages/System/DetailBenutzer"));
const Drucker = lazy(() => import("./pages/System/Drucker"));
const DetailDrucker = lazy(() => import("./pages/System/DetailDrucker"));
const Versandetiketten = lazy(() => import("./pages/System/Versandetiketten"));
const Mandanten = lazy(() => import("./pages/System/Mandanten"));

// LOGISTIK
const Kantinen = lazy(() => import("./pages/Logistik/Kantinen"));
const DetailKantine = lazy(() => import("./pages/Logistik/DetailKantine"));
const DisplayApp = lazy(() => import("./pages/Logistik/DisplayApp"));
const Touren = lazy(() => import("./pages/Logistik/Touren"));
const Produktionsorte = lazy(() => import("./pages/Logistik/Produktionsorte"));
const DetailProduktionsort = lazy(() => import("./pages/Logistik/DetailProduktionsort"));
const Produktionsarten = lazy(() => import("./pages/Logistik/Produktionsarten"));
const DetailProduktionsart = lazy(() => import("./pages/Logistik/DetailProduktionsart"));
const Zuschussarten = lazy(() => import("./pages/Logistik/Zuschussarten"));
const Essenausgabekonzepte = lazy(() => import("./pages/Logistik/Essenausgabekonzepte"));
const Bestellsysteme = lazy(() => import("./pages/Logistik/Bestellsysteme"));
const Zentralkuechen = lazy(() => import("./pages/Logistik/Zentralkuechen"));
const Kundengruppen = lazy(() => import("./pages/Logistik/Kundengruppen"));
const DetailKundengruppe = lazy(() => import("./pages/Logistik/DetailKundengruppe"));

const Routes: FC = () => {
  return (
    <BrowserRoutes>
      <Route path={""} element={<PrivateRoute />}>
        <Route index element={<Home />} />
        <Route path={"set-password"} element={<SetPassword />} />
        <Route path={"faq"} element={<FAQ />} />
        <Route path={"konto"} element={<Konto />} />
        <Route path={"ticket"} element={<Ticket />} />
        <Route path={"documents"} element={<PublicDocuments />} />
        <Route path={"formulars"}>
          <Route index element={<Formulars />} />
          <Route path={":uuid"} element={<Formular />} />
        </Route>

        <Route path={"shop"} element={<ShopProvider />}>
          <Route index element={<Shop />} />
          <Route path={"orders"}>
            <Route index element={<Orders />} />
            <Route path={":uuid"} element={<Order />} />
          </Route>
          <Route path={"cart"} element={<Cart />} />
        </Route>

        <Route path={"system"}>
          <Route index element={<System />} />
          <Route path={"backup"} element={<Backup />} />
          <Route path="benutzer">
            <Route index element={<Benutzer />} />
            <Route path={":uuid"} element={<DetailBenutzer />} />
          </Route>
          <Route path="printer">
            <Route index element={<Drucker />} />
            <Route path={":uuid"} element={<DetailDrucker />} />
          </Route>
          <Route path="versandetiketten" element={<Versandetiketten />} />
          <Route path={"mandanten"}>
            <Route index element={<Mandanten />} />
          </Route>
        </Route>

        <Route path={"kita"}>
          <Route index element={<Navigate to={"/"} />} />
          <Route index element={<Kita />} />
          <Route path="kitas" element={<Kitas />} />
          <Route path="kita-bestellung" element={<KitaBestellung />} />
        </Route>

        <Route path={"management"}>
          <Route index element={<Navigate to={"/"} />} />
          <Route path={"shop"}>
            <Route path={"orders"} element={<ShopOrders />} />
            <Route path={"vendors"} element={<ShopVendors />} />
          </Route>
          <Route path={"bewertungen"} element={<Bewertungen />} />
          <Route path={"documents"} element={<Documents />} />
          <Route path={"api-shop"}>
            <Route index element={<ApiShop />} />
            <Route path={"users/:uuid"} element={<ApiBenutzer />} />
          </Route>

          <Route path="arbeitnehmer">
            <Route index element={<Arbeitnehmer />} />
            <Route path={"abwesenheitspraemie"} element={<Abwesenheitspraemie />} />
            <Route path={":uuid"} element={<DetailArbeitnehmer />} />
          </Route>
          <Route path="abteilungen">
            <Route index element={<Abteilungen />} />
            <Route path=":uuid" element={<DetailAbteilung />} />
          </Route>
          <Route path="arbeitsvertraege">
            <Route index element={<Arbeitsvertraege />} />
            <Route path=":uuid" element={<DetailArbeitsvertrag />} />
          </Route>
          <Route path="urlaubsmanager">
            <Route index element={<Urlaubsmanager />} />
            <Route path={"abteilungsansicht"} element={<Abteilungsansicht />} />
          </Route>
          <Route path={"gesundheit"} element={<Gesundheit />} />
        </Route>

        <Route path={"logistik"}>
          <Route index element={<Navigate to={"/"} />} />
          <Route path="kantinen">
            <Route index element={<Kantinen />} />
            <Route path={":uuid"}>
              <Route index element={<DetailKantine />} />
              <Route path={"display-app"} element={<DisplayApp />} />
            </Route>
          </Route>
          <Route path="touren" element={<Touren />} />
          <Route path="produktionsorte">
            <Route index element={<Produktionsorte />} />
            <Route path=":uuid" element={<DetailProduktionsort />} />
          </Route>
          <Route path="produktionsarten">
            <Route index element={<Produktionsarten />} />
            <Route path=":uuid" element={<DetailProduktionsart />} />
          </Route>
          <Route path={"zuschussarten"} element={<Zuschussarten />} />
          <Route path={"essenausgabekonzepte"} element={<Essenausgabekonzepte />} />
          <Route path={"bestellsysteme"} element={<Bestellsysteme />} />
          <Route path={"zentralkuechen"} element={<Zentralkuechen />} />
          <Route path="kundengruppen">
            <Route index element={<Kundengruppen />} />
            <Route path=":uuid" element={<DetailKundengruppe />} />
          </Route>
        </Route>

        <Route path={"finanzen"}>
          <Route index element={<Navigate to={"/"} />} />
          <Route path={"abrechnung"} element={<Abrechnung />} />
          <Route path={"ueberstunden"}>
            <Route index element={<Ueberstunden />} />
            <Route path={"anfragen"} element={<UeberstundenAnfragen />} />
          </Route>
          <Route path={"minijob"}>
            <Route index element={<Minijob />} />
            <Route path={"multi"} element={<MultiMinijob />} />
          </Route>
          <Route path={"mehrarbeit"}>
            <Route index element={<Mehrarbeit />} />
            <Route path={"multi"} element={<MultiMehrarbeit />} />
          </Route>
          <Route path={"unternehmen"} element={<Unternehmen />} />
          <Route path={"kostenstellen"} element={<Kostenstellen />} />
          <Route path={"schaupp"}>
            <Route index element={<Schaupp />} />
            <Route path={":uuid"} element={<SchauppDetail />} />
            <Route path={"archiviert/:uuid"} element={<SchauppArchiviert />} />
          </Route>
        </Route>

        <Route path={"digitalsignage"}>
          <Route index element={<Navigate to={"/"} />} />
          <Route path={"displays"}>
            <Route index element={<Displays />} />
            <Route path={":uuid"} element={<DetailDisplay />} />
          </Route>
          <Route path={"gruppen"}>
            <Route index element={<DisplayGruppen />} />
            <Route path={":uuid"} element={<DetailGruppe />} />
          </Route>
          <Route path={"artikel"}>
            <Route index element={<Artikel />} />
            <Route path={":uuid"} element={<DetailArtikel />} />
          </Route>
          <Route path={"kronospan-displays"} element={<KronospanDisplays />} />
          <Route path={"kronospan-users"} element={<KronospanUsers />} />
        </Route>

        <Route path={"*"} element={<NotFound />} />
      </Route>
      <Route path={""} element={<Outlet />}>
        <Route path={"logout"} element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </BrowserRoutes>
  );
};

export default Routes;

import { FC, HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";

export const ProfilePopupInner: FC<{ children?: ReactNode }> = ({ children }) => (
  <div style={{ minWidth: 480, maxWidth: 480, margin: 0, padding: 0 }}>{children}</div>
);

export const NavigationItemsWrapper: FC<{ children?: ReactNode }> = ({ children }) => (
  <div style={{ minWidth: 320, maxWidth: 800, margin: 0, padding: 0 }}>{children}</div>
);

export const NavigationItemsInner: FC<{ children?: ReactNode }> = ({ children }) => (
  <div
    style={{
      maxHeight: "calc(100vh - 200px)",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      margin: 0,
      padding: 0,
    }}
  >
    {children}
  </div>
);

export const NavigationItemsGroup: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    className={
      "overflow-auto border-b-1 border-solid border-b-gray-100 after:block after:h-[6px] before:block before:h-[6px] flex-shrink-0"
    }
    {...props}
  >
    {props.children}
  </div>
);

export const NavigationItemsGroupHeader: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    style={{ lineHeight: "1.45455" }}
    className={
      "mt-[20px] mb-[6px] first-of-type:mt-[14px] py-0 px-[20px] text-navigation-gray text-[11px] font-extrabold uppercase"
    }
    {...props}
  >
    {props.children}
  </div>
);

export const NavigationItemsLink: FC<{ children?: ReactNode; to: string; onClick: () => void }> = ({
  children,
  to,
  onClick,
}) => (
  <Link
    onClick={onClick}
    to={to}
    className={
      "select-none hover:bg-creme bg-transparent cursor-pointer flex w-full min-h-[40px] m-0 py-[8px] px-[20px] gap-2 items-center border-none text-[14px] outline-none no-underline"
    }
  >
    {children}
  </Link>
);

export const NavigationItemsButton: FC<{ children?: ReactNode; onClick: () => void }> = ({ children, onClick }) => (
  <button
    onClick={onClick}
    type={"button"}
    className={
      "select-none hover:bg-creme bg-transparent cursor-pointer flex w-full min-h-[40px] m-0 py-[8px] px-[20px] gap-2 items-center border-none text-[14px] outline-none no-underline"
    }
  >
    {children}
  </button>
);

import "reflect-metadata";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./providers/AuthProvider";
import App from "./App";
import MessageProvider from "./providers/MessageProvider";
import { HelmetProvider } from "react-helmet-async";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import SidebarProvider from "./providers/SidebarProvider";
import "./index.css";
import { TooltipProvider } from "./providers/TooltipProvider";
import { render } from "react-dom";
import axios from "axios";

if (process.env.REACT_APP_STAGE === "production") {
  disableReactDevTools();
}

document.addEventListener("contextmenu", (e) => e.preventDefault());

const baseUrl =
  process.env.REACT_APP_STAGE === "development"
    ? "http://localhost:3333"
    : process.env.REACT_APP_STAGE === "staging"
    ? "https://rest.gourmetta.de"
    : "https://api.gutes-essen.bio";

axios.defaults.baseURL = baseUrl;
axios.defaults.withCredentials = process.env.NODE_ENV !== "development";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config && config.headers) {
      // @ts-ignore
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

render(
  <React.StrictMode>
    <TooltipProvider>
      <HelmetProvider>
        <BrowserRouter>
          <SidebarProvider>
            <MessageProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </MessageProvider>
          </SidebarProvider>
        </BrowserRouter>
      </HelmetProvider>
    </TooltipProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

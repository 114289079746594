import { FC } from "react";
import styled from "@emotion/styled";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: "white";
}

const ProgressWrapper = styled("span", {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "height" && prop !== "color",
})<{
  width?: number | string;
  height?: number | string;
  color?: "white";
}>(({ color, width, height }) => ({
  width: width || 40,
  height: height || 40,
  display: "inline-block",
  color: "#0065ff",
  ...(color === "white" && {
    color: "#fff",
  }),
  animation: "circular-progress 1.4s linear infinite",
  fontWeight: "bold",
  cursor: "pointer",
}));

const Progress = styled("svg", { shouldForwardProp: (prop) => prop !== "color" })<{ color?: "white" }>(({ color }) => ({
  display: "block",
  overflow: "hidden",
  color: "#0065ff",
  ...(color === "white" && {
    color: "#fff",
  }),
}));

const Circle = styled("circle")(() => ({
  stroke: "currentcolor",
  strokeDasharray: "80px,200px",
  strokeDashoffset: 0,
  animation: "circular-progress-inner 1.4s ease-in-out infinite",
  transformOrigin: "0px 0px",
}));

const CircularProgress: FC<Props> = (props) => {
  return (
    <ProgressWrapper data-testid={"circular-progress"} color={props.color} width={props.width} height={props.height}>
      <Progress color={props.color} viewBox="22 22 44 44">
        <Circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></Circle>
      </Progress>
    </ProgressWrapper>
  );
};

export default CircularProgress;

import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { FC, ReactNode } from "react";

const SubmittedCardBoxStyled = styled(motion.div, { shouldForwardProp: (prop) => prop !== "color" })<{
  color: "green" | "red" | "white";
}>(({ color }) => ({
  position: "fixed",
  bottom: "3rem",
  left: "50%",
  ...(color === "green" && {
    backgroundColor: "rgb(64,190,22)",
    color: "#fff",
  }),
  ...(color === "red" && {
    backgroundColor: "rgb(255,65,65)",
    color: "#fff",
  }),
  ...(color === "white" && {
    backgroundColor: "#fff",
    color: "#333",
  }),
  borderRadius: 12,
  zIndex: 100,
  boxShadow: "0 1px 2px rgba(0,0,0,.3)",
  padding: ".7rem 1rem",
}));

export const SubmittedCardBox: FC<{ color: "white" | "green" | "red"; children?: ReactNode }> = (props) => {
  return (
    <SubmittedCardBoxStyled
      color={props.color}
      initial={{ y: "30%", opacity: 0, x: "-50%" }}
      animate={{ y: 0, opacity: 1, x: "-50%" }}
      exit={{ y: "30%", opacity: 0, x: "-50%" }}
    >
      {props.children}
    </SubmittedCardBoxStyled>
  );
};

import { useAuth } from "../providers/AuthProvider";
import { useEffect, useState } from "react";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class Essenausgabekonzept {
  uuid: string;
  name: string;
  created: string;
  updated: string;
}

type UseEssenausgabeKonzepte = {
  essenausgabekonzepte: Essenausgabekonzept[];
  loading: boolean;
};

type Props = {
  loadKonzepte?: boolean;
};

export const useEssenausgabeKonzepte = (props: Props = { loadKonzepte: true }): UseEssenausgabeKonzepte => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [loading, setLoading] = useState<boolean>(true);
  const [essenausgabekonzepte, setEssenausgabekonzepte] = useState<Essenausgabekonzept[]>([]);

  useEffect(() => {
    let cancel = false;

    if (auth.canReadEssenausgabekonzepte) {
      if (props.loadKonzepte) {
        if (!cancel) {
          setLoading(true);
        }

        axios
          .get<Essenausgabekonzept[]>("/essenausgabekonzepte")
          .then((response) => {
            if (!cancel) {
              setEssenausgabekonzepte(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    return () => {
      cancel = true;
    };
  }, [auth.canReadEssenausgabekonzepte, handleApiError, props.loadKonzepte]);

  return {
    essenausgabekonzepte,
    loading,
  };
};

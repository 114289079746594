import styled from "@emotion/styled";
import React from "react";
import { motion } from "framer-motion";

const BackdropStyled = styled(motion.div, { shouldForwardProp: (prop) => prop !== "blur" })<{ blur?: number }>(
  ({ blur }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    inset: 0,
    backgroundColor: "rgba(9, 30, 66, 0.54)",
    zIndex: 1400,
    ...(typeof blur !== "undefined" && {
      backdropFilter: "blur(" + blur + "px)",
      backgroundColor: "rgb(148 163 184 / 0.25)",
      zIndex: 100000,
    }),
  })
);

interface BackdropProps {
  onClose: () => void;
  children?: React.ReactNode;
  blur?: number;
}

const Backdrop: React.FC<BackdropProps> = (props) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    props.onClose();
  };

  return (
    <BackdropStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
      aria-hidden={"true"}
      blur={props.blur}
      onClick={handleClick}
    >
      {props.children}
    </BackdropStyled>
  );
};

export default Backdrop;

import { useAuth } from "../providers/AuthProvider";
import { useEffect, useMemo, useState } from "react";
import { formatEuro } from "../utils/euro";
import axios, { AxiosResponse } from "axios";
import { IsNotEmpty, IsOptional, IsString, IsUUID } from "class-validator";
import { IMeal } from "./useMeals";
import { IArtikel } from "./useArtikel";
import { IBenutzer } from "./useBenutzer";
import { IKantine } from "./useKantinen";
import { useMessages } from "../providers/MessageProvider";

export class GroupPriceEntity {
  uuid?: string;
  artikel?: IArtikel;
  group?: IDisplayGroup;
  textPrice?: string;
  price: number;
  created?: string;
  updated?: string;
}

export class IDisplayGroup {
  @IsOptional()
  @IsUUID()
  uuid: string;

  @IsNotEmpty({ message: "Name muss angegeben werden" })
  @IsString({ message: "Name muss eine Texteingabe sein" })
  name?: string;

  prices?: GroupPriceEntity[];

  created?: string;
  updated?: string;

  artikelKantinen?: IKantine[];

  mealKantinen?: IKantine[];

  meals?: IMeal[];
  artikel?: IArtikel[];

  users?: IBenutzer[];
}

type IUseDisplayGroups = {
  deleteGroup: (uuid: string) => Promise<void>;
  updateGroup: (data: IDisplayGroup) => Promise<IDisplayGroup>;
  createGroup: (data: IDisplayGroup) => Promise<IDisplayGroup>;
  groupsLoading: boolean;
  groups: IDisplayGroup[];
  getGroup: (uuid: string) => Promise<IDisplayGroup>;
  updateDisplayGroupArtikel: (uuid: string, artikel: IArtikel[]) => Promise<AxiosResponse<void>>;
  READ_DISPLAY_GROUPS: boolean;
  UPDATE_DISPLAY_GROUPS: boolean;
  CREATE_DISPLAY_GROUPS: boolean;
  DELETE_DISPLAY_GROUPS: boolean;
};

type Props = {
  loadGroups?: boolean;
};

export const useDisplayGroups = (props: Props = { loadGroups: true }): IUseDisplayGroups => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [groups, setGroups] = useState<IDisplayGroup[]>([]);
  const [groupsLoading, setGroupsLoading] = useState<boolean>(true);

  const READ_DISPLAY_GROUPS = useMemo(
    () => Boolean(auth.user?.permissions.includes("READ_DISPLAY_GRUPPEN")),
    [auth.user]
  );
  const UPDATE_DISPLAY_GROUPS = useMemo(
    () => Boolean(auth.user?.permissions.includes("UPDATE_DISPLAY_GROUPS")),
    [auth.user]
  );
  const CREATE_DISPLAY_GROUPS = useMemo(
    () => Boolean(auth.user?.permissions.includes("CREATE_DISPLAY_GROUPS")),
    [auth.user]
  );
  const DELETE_DISPLAY_GROUPS = useMemo(
    () => Boolean(auth.user?.permissions.includes("DELETE_DISPLAY_GROUPS")),
    [auth.user]
  );

  useEffect(() => {
    let cancel = false;

    if (READ_DISPLAY_GROUPS) {
      if (props.loadGroups) {
        if (!cancel) {
          setGroupsLoading(true);
        }
        axios
          .get<IDisplayGroup[]>("/digitalsignage/groups")
          .then((response) => {
            if (!cancel) {
              for (let i = 0; i < response.data.length; i++) {
                if (typeof response.data[i].artikel !== "undefined") {
                  for (let x = 0; x < response.data[i].artikel!.length; x++) {
                    if (typeof response.data[i].artikel![x].prices !== "undefined") {
                      for (let y = 0; y < response.data[i].artikel![x].prices!.length; y++) {
                        response.data[i].artikel![x].prices![y].textPrice = formatEuro(
                          response.data[i].artikel![x].prices![y].price
                        );
                      }
                    }
                  }
                }
              }
              setGroups(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setGroupsLoading(false);
            }
          });
      } else {
        if (!cancel) {
          setGroupsLoading(false);
        }
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_DISPLAY_GROUPS, props.loadGroups, handleApiError]);

  const deleteGroup = (uuid: string): Promise<void> => {
    if (DELETE_DISPLAY_GROUPS) {
      return axios.delete("/digitalsignage/groups/" + uuid).then(() => {
        const copy = [...groups];
        for (let i = 0; i < groups.length; i++) {
          if (copy[i].uuid === uuid) {
            copy.splice(i, 1);
            break;
          }
        }
        setGroups(copy);
      });
    }
    return Promise.reject("unauthorized");
  };

  const updateGroup = (data: IDisplayGroup): Promise<IDisplayGroup> => {
    if (UPDATE_DISPLAY_GROUPS) {
      delete data.mealKantinen;
      delete data.artikelKantinen;
      delete data.users;
      delete data.meals;

      return axios.patch<IDisplayGroup>("/digitalsignage/groups", data).then((response) => {
        const copy = [...groups];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === response.data.uuid) {
            copy[i] = response.data;
            break;
          }
        }
        setGroups(copy);
        return response.data;
      });
    }
    return Promise.reject("unauthorized");
  };

  const createGroup = (data: IDisplayGroup): Promise<IDisplayGroup> => {
    if (CREATE_DISPLAY_GROUPS) {
      return axios.post<IDisplayGroup>("/digitalsignage/groups", data).then((response) => {
        const copy = [...groups];
        copy.unshift(response.data);
        setGroups(copy);
        return response.data;
      });
    }
    return Promise.reject("unauthorized");
  };

  const getGroup = (uuid: string): Promise<IDisplayGroup> => {
    if (READ_DISPLAY_GROUPS) {
      return axios.get<IDisplayGroup>("/digitalsignage/groups/" + uuid).then((response) => response.data);
    }
    return Promise.reject("unauthorized");
  };

  const updateDisplayGroupArtikel = (uuid: string, artikel: IArtikel[]) =>
    axios.post<void>("/digitalsignage/groups/" + uuid + "/artikel", { artikel });

  return {
    createGroup,
    updateGroup,
    deleteGroup,
    groupsLoading,
    updateDisplayGroupArtikel,
    groups,
    getGroup,
    DELETE_DISPLAY_GROUPS,
    CREATE_DISPLAY_GROUPS,
    UPDATE_DISPLAY_GROUPS,
    READ_DISPLAY_GROUPS,
  };
};

import React, { FC, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavigationElement as IINavigationElement, NavigationElementGroup } from "../../providers/AuthProvider";
import { useAuth } from "../../providers/AuthProvider";
import Portal from "../Layout/Portal";
import {
  NavigationItemsButton,
  NavigationItemsGroup,
  NavigationItemsGroupHeader,
  NavigationItemsInner,
  NavigationItemsLink,
  NavigationItemsWrapper,
  ProfilePopupInner,
} from "./index";
import { useClickOutside } from "../../hooks/useClickoutside";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  InformationCircleIcon,
  TicketIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useSidebar } from "../../providers/SidebarProvider";
import { useMessages } from "../../providers/MessageProvider";
import Logo from "../Layout/Logo";

const NavigationOuter = styled("header")(() => ({
  zIndex: 200,
  position: "relative",
  top: 0,
  margin: 0,
  padding: 0,
}));

const NavigationWrapper = styled("div")(() => ({
  gridArea: "top-navigation / top-navigation / top-navigation / top-navigation",
  height: 56,
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2,
}));

const Header = styled("header")(() => ({
  webkitBoxAlign: "center",
  alignItems: "center",
  boxSizing: "border-box",
  display: "flex",
  flexShrink: 0,
  webkitBoxPack: "justify",
  justifyContent: "space-between",
  paddingLeft: 12,
  paddingRight: 12,
  height: 56,
  position: "relative",
  backgroundColor: "#fff",
  color: "#6B778C",
  "&::after": {
    content: "''",
    position: "absolute",
    left: 0,
    right: 0,
    top: "100%",
    height: 1,
    borderBottom: "1px solid rgba(0,0,0,.1)",
  },
}));

const Nav = styled("nav")(() => ({
  webkitBoxAlign: "center",
  alignItems: "center",
  display: "flex",
  webkitBoxFlex: 1,
  flexGrow: 1,
  minWidth: 0,
  height: "inherit",
}));

export const HomeLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  gap: ".5rem",
  paddingLeft: ".4rem",
  paddingRight: ".4rem",
  transition: "all .2s ease",
  borderRadius: 6,
  color: "rgb(52, 69, 99)",
  height: "2.28571em",
  lineHeight: "2.28571em",
  marginRight: "1rem",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "rgba(222,235,255,0.9)",
    color: "rgb(0, 82, 204)",
  },
}));

const LinkItems = styled("div")(() => ({
  webkitBoxAlign: "stretch",
  alignItems: "stretch",
  display: "flex",
  webkitBoxFlex: 1,
  flex: "1 0 0px",
  height: "100%",
  position: "relative",
}));

const NavigationLinkWrapper = styled("div")(() => ({
  flexShrink: 0,
  margin: "0 4px",
}));

interface INavigationLink {
  active: boolean;
}

const NavigationLink = styled("div", { shouldForwardProp: (prop) => prop !== "active" })<INavigationLink>(
  ({ active }) => ({
    display: "flex",
    flexDirection: "column",
    webkitBoxPack: "center",
    justifyContent: "center",
    webkitBoxAlign: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
    ...(active && {
      "&::after": {
        position: "absolute",
        bottom: 0,
        left: 4,
        right: 4,
        content: "''",
        height: 3,
        backgroundColor: "#0052CC",
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
      },
    }),
  })
);

interface INavigationButton {
  active: boolean;
}

const NavigationButton = styled("button", { shouldForwardProp: (prop) => prop !== "active" })<INavigationButton>(
  ({ active }) => ({
    webkitBoxAlign: "baseline",
    alignItems: "baseline",
    boxSizing: "border-box",
    display: "inline-flex",
    fontStyle: "normal",
    fontFamily: "inherit",
    fontWeight: 500,
    maxWidth: "100%",
    position: "relative",
    textAlign: "center",
    whiteSpace: "nowrap",
    backgroundImage: "unset",
    backgroundPositionX: "unset",
    backgroundPositionY: "unset",
    backgroundSize: "unset",
    backgroundRepeatX: "unset",
    backgroundRepeatY: "unset",
    backgroundAttachment: "unset",
    backgroundOrigin: "unset",
    backgroundClip: "unset",
    color: "#344563",
    cursor: "pointer",
    height: "2.28571em",
    lineHeight: "2.28571em",
    verticalAlign: "middle",
    width: "auto",
    webkitBoxPack: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 0,
    borderRadius: 3,
    textDecoration: "none",
    transition: "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
    padding: "0px 4px",
    outline: "none",
    fontSize: 14,
    "&:hover": {
      color: "#0052CC",
      backgroundColor: "rgba(222,235,255,0.9)",
    },
    ...(active && {
      color: "#0052CC",
    }),
  })
);

const NavigationButtonText = styled("span")(() => ({
  transition: "opacity 0.3s ease 0s",
  opacity: 1,
  margin: "0px 2px",
  webkitBoxFlex: 1,
  flexGrow: 1,
  flexShrink: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const NavigationButtonIconOuter = styled("span")(() => ({
  transition: "opacity 0.3s ease 0s",
  opacity: 1,
  alignSelf: "center",
  display: "flex",
  webkitBoxFlex: 0,
  flexGrow: 0,
  flexShrink: 0,
  lineHeight: 0,
  fontSize: 0,
  userSelect: "none",
  margin: "0px 2px",
}));

const NavigationButtonIconWrapper = styled("span")(() => ({
  marginLeft: -8,
  marginRight: -8,
  opacity: 0.51,
}));

const NavigationButtonIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path
        d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

interface INavigationElement {
  group: NavigationElementGroup;
  handleNavigationButtonClick: (event: React.MouseEvent<HTMLButtonElement>, group: NavigationElementGroup) => void;
}

const NavigationElement: FC<INavigationElement> = ({ group, handleNavigationButtonClick }) => {
  const location = useLocation();

  const isCurrentRoute = useMemo(() => location.pathname.startsWith(group.to), [location.pathname, group.to]);

  return (
    <NavigationLinkWrapper>
      <NavigationLink active={isCurrentRoute}>
        <NavigationButton
          active={isCurrentRoute}
          aria-expanded={"false"}
          aria-haspopup={"true"}
          tabIndex={0}
          onClick={(e) => handleNavigationButtonClick(e, group)}
        >
          <NavigationButtonText>{group.name}</NavigationButtonText>
          {group.elements.length > 0 && (
            <NavigationButtonIconOuter>
              <NavigationButtonIconWrapper>
                <NavigationButtonIcon />
              </NavigationButtonIconWrapper>
            </NavigationButtonIconOuter>
          )}
        </NavigationButton>
      </NavigationLink>
    </NavigationLinkWrapper>
  );
};

const RightContent = styled("div")(() => ({
  webkitBoxAlign: "center",
  alignItems: "center",
  display: "flex",
  flexShrink: 0,
  margin: 0,
  padding: 0,
  "& > *": {
    flexShrink: 0,
    marginRight: 4,
  },
}));

const ProfileWrapper = styled("div")(() => ({
  position: "relative",
  margin: 0,
  padding: 0,
  "&::before": {
    borderRadius: "50%",
    width: 36,
    left: "50%",
    transform: "translateX(-50%)",
    content: "''",
    position: "absolute",
    top: 0,
    height: "100%",
    boxShadow: "0 0 0 3px #C0B6F2",
    opacity: 0,
    transition: "opacity 0.1s ease-in-out 0s",
    pointerEvents: "none",
  },
  "&::after": {
    borderRadius: "50%",
    width: 36,
    left: "50%",
    transform: "translateX(-50%)",
    content: "''",
    position: "absolute",
    top: 0,
    height: "100%",
    boxShadow: "0 0 0 3px #C0B6F2, 0 0 11px rgba(101,84,192,1)",
    opacity: 0,
    transition: "opacity 0.1s ease-in-out 0s",
    pointerEvents: "none",
  },
}));

const ProfileButton = styled("button")(() => ({
  webkitBoxAlign: "baseline",
  alignItems: "baseline",
  boxSizing: "border-box",
  display: "flex",
  fontSize: "inherit",
  fontStyle: "normal",
  fontFamily: "inherit",
  fontWeight: 500,
  maxWidth: "100%",
  position: "relative",
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundImage: "unset",
  backgroundPositionX: "unset",
  backgroundPositionY: "unset",
  backgroundSize: "unset",
  backgroundRepeatX: "unset",
  backgroundRepeatY: "unset",
  backgroundAttachment: "unset",
  backgroundOrigin: "unset",
  backgroundClip: "unset",
  color: "#344563",
  cursor: "pointer",
  height: "auto",
  lineHeight: "2.28571em",
  verticalAlign: "middle",
  width: "auto",
  webkitBoxPack: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  borderWidth: 0,
  borderRadius: "100%",
  textDecoration: "none",
  transition: "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
  padding: 4,
  outline: "none",
  margin: "0px 2px",
  "&:hover": {
    backgroundColor: "rgba(222,235,255,0.9)",
  },
}));

const ProfileInner = styled("span")(() => ({
  borderRadius: "100%",
  backgroundColor: "#0052CC",
  color: "#fff",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: 24,
  height: 24,
  fontSize: 11,
}));

const Navigation: FC = () => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const sidebar = useSidebar();
  const navigate = useNavigate();
  const navRef = useRef<HTMLDivElement | null>(null);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const [navigationElements, setNavigationElements] = useState<IINavigationElement[]>([]);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [navigationGroup, setNavigationGroup] = useState<NavigationElementGroup | null>(null);

  useClickOutside(navRef, () => {
    setNavigationElements([]);
    setY(0);
    setX(0);
    setNavigationGroup(null);
  });

  useClickOutside(profileRef, () => {
    setX(0);
    setY(0);
    setShowProfile(false);
  });

  const handleLinkClick = () => {
    setX(0);
    setY(0);
    setNavigationElements([]);
    setNavigationGroup(null);
    setShowProfile(false);
  };

  const getLinkRoute = (element: IINavigationElement) => {
    if (navigationGroup) {
      return navigationGroup.to + element.to + (element.tab !== null ? "?tab=" + element.tab : "");
    }
    return element.to;
  };

  const handleNavigationButtonClick = (event: React.MouseEvent<HTMLButtonElement>, group: NavigationElementGroup) => {
    if (group.elements.length <= 0) {
      navigate(group.to);
      setX(0);
      setY(0);
      setNavigationElements([]);
      setNavigationGroup(null);
      return;
    }
    if (x && y) {
      setX(0);
      setY(0);
      setNavigationElements([]);
      setNavigationGroup(null);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setX(rect.left);
      setY(rect.top + rect.height + 10);
      setNavigationElements(group.elements);
      setNavigationGroup(group);
    }
  };

  const handleSidebarViewChange = () => {
    sidebar.toggleSidebar(true);
    sidebar.updateRemote(true);
  };

  const groups = useMemo(() => {
    if (!navigationGroup) return [];
    if (!navigationGroup.elements) return [];

    let groups: { name: string; elements: IINavigationElement[]; to: string }[] = [];

    for (let i = 0; i < navigationGroup.elements.length; i++) {
      if (navigationGroup.elements[i].subgroup !== null) {
        if (groups.length === 0) {
          groups.push({
            name: navigationGroup.elements[i].subgroup || "",
            elements: [navigationGroup.elements[i]],
            to: navigationGroup.to,
          });
        } else {
          let found = false;
          for (let x = 0; x < groups.length; x++) {
            if (groups[x].name === navigationGroup.elements[i].subgroup) {
              groups[x].elements.push(navigationGroup?.elements[i]);
              found = true;
              break;
            }
          }
          if (!found) {
            groups.push({
              name: navigationGroup?.elements[i].subgroup || "",
              elements: [navigationGroup.elements[i]],
              to: navigationGroup.to,
            });
          }
        }
      }
    }
    return groups;
  }, [navigationGroup]);

  const logout = () => {
    auth
      .logout()
      .then(() => navigate("/login"))
      .catch(handleApiError);
  };

  return (
    <NavigationOuter>
      <NavigationWrapper>
        <Header role={"banner"}>
          <Nav>
            <HomeLink to={"/"}>
              <Logo />
              central
            </HomeLink>
            <LinkItems>
              {auth.user &&
                auth.user.navigation.map((group) => (
                  <NavigationElement
                    group={group}
                    handleNavigationButtonClick={handleNavigationButtonClick}
                    key={group.uuid}
                  />
                ))}
            </LinkItems>
          </Nav>
          <RightContent>
            <span>
              <span style={{ display: "block" }}>
                <ProfileWrapper>
                  <div>
                    <ProfileButton onClick={() => setShowProfile(true)} tabIndex={0} title={"Ihre Einstellungen"}>
                      <ProfileInner>
                        {auth.user &&
                          auth.user.vorname.charAt(0).toUpperCase() + auth.user.nachname.charAt(0).toUpperCase()}
                      </ProfileInner>
                    </ProfileButton>
                  </div>
                </ProfileWrapper>
              </span>
            </span>
          </RightContent>
        </Header>
      </NavigationWrapper>
      <Portal wrapperId={"app-portal"}>
        {navigationGroup && !showProfile && (
          <div
            ref={navRef}
            className={
              "fixed z-[400] w-[400px] top-0 left-0 block bg-white rounded-md shadow-card overflow-auto m-0 p-0"
            }
            style={{
              transform: "translate3d(" + x + "px, " + y + "px, 0px)",
            }}
          >
            <span>
              <NavigationItemsWrapper>
                <NavigationItemsInner>
                  {groups.map((group, index) => (
                    <NavigationItemsGroup key={index} data-section={"true"} role={"group"}>
                      <span>
                        <NavigationItemsGroupHeader>{group.name}</NavigationItemsGroupHeader>
                        {group.elements.map((element) => (
                          <NavigationItemsLink onClick={handleLinkClick} to={getLinkRoute(element)} key={element.uuid}>
                            {element.text}
                          </NavigationItemsLink>
                        ))}
                      </span>
                    </NavigationItemsGroup>
                  ))}
                  <NavigationItemsGroup data-section={"true"} role={"group"}>
                    <span>
                      {navigationElements
                        .filter((el) => el.subgroup === null)
                        .map((element) => (
                          <NavigationItemsLink onClick={handleLinkClick} to={getLinkRoute(element)} key={element.uuid}>
                            {element.text}
                          </NavigationItemsLink>
                        ))}
                    </span>
                  </NavigationItemsGroup>
                </NavigationItemsInner>
              </NavigationItemsWrapper>
            </span>
          </div>
        )}
        {showProfile && (
          <div
            className={
              "fixed z-[400] w-[400px] top-[60px] right-4 block bg-white rounded-md shadow-card overflow-hidden m-0 p-0"
            }
            ref={profileRef}
          >
            <span>
              <ProfilePopupInner>
                <NavigationItemsGroup>
                  <span>
                    <NavigationItemsGroupHeader>Konto</NavigationItemsGroupHeader>
                    <NavigationItemsLink onClick={handleLinkClick} to={"/konto?tab=allgemein"}>
                      <UserCircleIcon className={"w-4 h-4"} />
                      Konto verwalten
                    </NavigationItemsLink>
                  </span>
                </NavigationItemsGroup>
                <NavigationItemsGroup>
                  <span>
                    <NavigationItemsGroupHeader>Hilfe</NavigationItemsGroupHeader>
                    <NavigationItemsLink onClick={handleLinkClick} to={"/faq"}>
                      <InformationCircleIcon className={"w-4 h-4"} />
                      FAQ
                    </NavigationItemsLink>
                    <NavigationItemsLink onClick={handleLinkClick} to={"/ticket"}>
                      <TicketIcon className={"w-4 h-4"} />
                      Ticket erstellen
                    </NavigationItemsLink>
                  </span>
                </NavigationItemsGroup>
                <NavigationItemsGroup>
                  <span>
                    <label
                      className={
                        "flex flex-row gap-2 select-none hover:bg-creme bg-transparent cursor-pointer flex w-full min-h-[40px] m-0 py-[8px] px-[20px] items-center border-none text-[14px] outline-none no-underline"
                      }
                    >
                      <input
                        className={"cursor-pointer hidden"}
                        checked={sidebar.sidebarOpen}
                        onChange={handleSidebarViewChange}
                        type={"checkbox"}
                      />
                      <ArrowsRightLeftIcon className={"w-4 h-4"} />
                      Sidebar Ansicht
                    </label>
                    <NavigationItemsButton onClick={logout}>
                      <ArrowLeftOnRectangleIcon className={"w-4 h-4"} />
                      Ausloggen
                    </NavigationItemsButton>
                  </span>
                </NavigationItemsGroup>
              </ProfilePopupInner>
            </span>
          </div>
        )}
      </Portal>
    </NavigationOuter>
  );
};

export default Navigation;

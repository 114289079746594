import {
  BanknotesIcon,
  BuildingOffice2Icon,
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  HomeModernIcon,
  ShoppingCartIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import {
  UsersIcon as UsersIconOutlined,
  BanknotesIcon as BankNotesIconOutlined,
  BuildingOffice2Icon as BuildingOffice2IconOutlined,
  Cog6ToothIcon as Cog6ToothIconOutlined,
  ShoppingCartIcon as ShoppingCartIconOutlined,
  VideoCameraIcon as VideoCameraIconOutlined,
  ChatBubbleOvalLeftIcon as ChatBubbleOvalLeftIconOutlined,
  HomeModernIcon as HomeModernIconOutlined,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { useLocation } from "react-router-dom";

interface SidebarIconProps {
  to: string;
}

const SidebarIcon: FC<SidebarIconProps> = ({ to }) => {
  const location = useLocation();

  switch (to) {
    case "/management": {
      if (location.pathname.startsWith(to)) {
        return <UsersIcon className={"w-6 h-6"} />;
      } else {
        return <UsersIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/finanzen": {
      if (location.pathname.startsWith(to)) {
        return <BanknotesIcon className={"w-6 h-6"} />;
      } else {
        return <BankNotesIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/haustechnik": {
      if (location.pathname.startsWith(to)) {
        return <BuildingOffice2Icon className={"w-6 h-6"} />;
      } else {
        return <BuildingOffice2IconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/system": {
      if (location.pathname.startsWith(to)) {
        return <Cog6ToothIcon className={"w-6 h-6"} />;
      } else {
        return <Cog6ToothIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/shop": {
      if (location.pathname.startsWith(to)) {
        return <ShoppingCartIcon className={"w-6 h-6"} />;
      } else {
        return <ShoppingCartIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/digitalsignage": {
      if (location.pathname.startsWith(to)) {
        return <VideoCameraIcon className={"w-6 h-6"} />;
      } else {
        return <VideoCameraIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/workflows": {
      if (location.pathname.startsWith(to)) {
        return <ChatBubbleOvalLeftIcon className={"w-6 h-6"} />;
      } else {
        return <ChatBubbleOvalLeftIconOutlined className={"w-6 h-6"} />;
      }
    }
    case "/kita": {
      if (location.pathname.startsWith(to)) {
        return <HomeModernIcon className={"w-6 h-6"} />;
      } else {
        return <HomeModernIconOutlined className={"w-6 h-6"} />;
      }
    }
    default:
      return <XCircleIcon className={"w-6 h-6"} />;
  }
};

export default SidebarIcon;

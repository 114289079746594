import { useAuth } from "../providers/AuthProvider";
import { useEffect, useMemo, useState } from "react";
import { IKantine } from "./useKantinen";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class Produktionsort {
  uuid?: string;
  kantinen?: IKantine[];
  name: string;
}

type IUseProduktionsorte = {
  createProduktionsort: (data: Produktionsort) => Promise<Produktionsort>;
  produktionsorte: Produktionsort[];
  loading: boolean;
  getProduktionsort: (uuid: string) => Promise<Produktionsort>;
  updateProduktionsort: (data: Produktionsort) => Promise<Produktionsort>;
  READ_PRODUKTIONSORTE: boolean;
  CREATE_PRODUKTIONSORTE: boolean;
  UPDATE_PRODUKTIONSORTE: boolean;
  DELETE_PRODUKTIONSORTE: boolean;
};

type Props = {
  load?: boolean;
};

export const useProduktionsorte = (props: Props = { load: true }): IUseProduktionsorte => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [loading, setLoading] = useState<boolean>(true);
  const [produktionsorte, setProduktionsorte] = useState<Produktionsort[]>([]);

  const READ_PRODUKTIONSORTE = useMemo(
    () => Boolean(auth.user?.permissions.includes("READ_PRODUKTIONSORTE")),
    [auth.user]
  );
  const UPDATE_PRODUKTIONSORTE = useMemo(
    () => Boolean(auth.user?.permissions.includes("UPDATE_PRODUKTIONSORTE")),
    [auth.user]
  );
  const CREATE_PRODUKTIONSORTE = useMemo(
    () => Boolean(auth.user?.permissions.includes("INSERT_PRODUKTIONSORTE")),
    [auth.user]
  );
  const DELETE_PRODUKTIONSORTE = useMemo(
    () => Boolean(auth.user?.permissions.includes("DELETE_PRODUKTIONSORTE")),
    [auth.user]
  );

  useEffect(() => {
    let cancel = false;

    if (READ_PRODUKTIONSORTE) {
      if (props.load) {
        if (!cancel) {
          setLoading(true);
        }
        axios
          .get<Produktionsort[]>("/produktionsorte")
          .then((response) => {
            if (!cancel) {
              setProduktionsorte(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        if (!cancel) {
          setLoading(false);
        }
      }
    } else {
      if (!cancel) {
        setLoading(false);
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_PRODUKTIONSORTE, handleApiError, props.load]);

  const getProduktionsort = (uuid: string) =>
    axios.get<Produktionsort>("/produktionsorte/" + uuid).then((response) => response.data);

  const createProduktionsort = (data: Produktionsort) =>
    axios.post<Produktionsort>("/produktionsorte", data).then((response) => {
      const copy = [...produktionsorte];
      copy.unshift(response.data);
      setProduktionsorte(copy);
      return response.data;
    });

  const updateProduktionsort = (data: Produktionsort) =>
    axios.patch<Produktionsort>("/produktionsorte", data).then((response) => {
      const copy = [...produktionsorte];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === response.data.uuid) {
          copy[i] = response.data;
          break;
        }
      }
      setProduktionsorte(copy);
      return response.data;
    });

  return {
    updateProduktionsort,
    loading,
    createProduktionsort,
    produktionsorte,
    getProduktionsort,
    READ_PRODUKTIONSORTE,
    CREATE_PRODUKTIONSORTE,
    UPDATE_PRODUKTIONSORTE,
    DELETE_PRODUKTIONSORTE,
  };
};

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { AnimatePresence, motion, MotionValue, useMotionValue } from "framer-motion";
import Portal from "../components/Layout/Portal";
import styled from "@emotion/styled";

const TooltipStyled = styled(motion.div)(() => ({
  position: "fixed",
  zIndex: 10,
  fontSize: 11,
  borderRadius: 3,
  backgroundColor: "rgba(0,0,0,.8)",
  color: "#fff",
  padding: "4px 6px",
  maxWidth: 200,
  width: 200,
  whiteSpace: "pre-wrap",
  pointerEvents: "none",
  top: 0,
  left: 0,
}));

type IUseTooltipProvider = {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  x: MotionValue<number>;
  y: MotionValue<number>;
};

const Context = createContext({} as IUseTooltipProvider);
export const useTooltip = (): IUseTooltipProvider => useContext(Context);
const useTooltipProvider = (): IUseTooltipProvider => {
  const [text, setText] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  return {
    text,
    setText,
    show,
    setShow,
    x,
    y,
  };
};
export const TooltipProvider = ({ children }: { children: ReactNode }) => {
  const tooltip = useTooltipProvider();

  return (
    <Context.Provider value={tooltip}>
      {children}
      <Portal wrapperId={"tooltip"}>
        <AnimatePresence>
          {tooltip.show && (
            <TooltipStyled
              style={{ x: tooltip.x, y: tooltip.y }}
              data-testid={"tooltip"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {tooltip.text}
            </TooltipStyled>
          )}
        </AnimatePresence>
      </Portal>
    </Context.Provider>
  );
};

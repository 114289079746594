import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { IKantine } from "./useKantinen";
import { useMessages } from "../providers/MessageProvider";
import axios from "axios";

export class Kundengruppe {
  uuid?: string;
  kantinen?: IKantine[];
  name: string;
}

type UseKundengruppen = {
  kundengruppen: Kundengruppe[];
  createKundengruppe: (data: Kundengruppe) => Promise<Kundengruppe>;
  deleteKundengruppe: (uuid: string) => Promise<void>;
  getKundengruppe: (uuid: string) => Promise<Kundengruppe>;
  updateKundengruppe: (data: Kundengruppe) => Promise<Kundengruppe>;
  loading: boolean;
  READ_KUNDENGRUPPEN: boolean;
  UPDATE_KUNDENGRUPPEN: boolean;
  CREATE_KUNDENGRUPPEN: boolean;
  DELETE_KUNDENGRUPPEN: boolean;
};

type Props = {
  loadKundengruppen?: boolean;
};

export const useKundengruppen = (props: Props = { loadKundengruppen: true }): UseKundengruppen => {
  const auth = useAuth();
  const { handleApiError } = useMessages();
  const [kundengruppen, setKundengruppen] = useState<Kundengruppe[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const READ_KUNDENGRUPPEN = useMemo(() => Boolean(auth.user?.permissions.includes("READ_KUNDENGRUPPEN")), [auth.user]);

  const UPDATE_KUNDENGRUPPEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("UPDATE_KUNDENGRUPPEN")),
    [auth.user]
  );

  const CREATE_KUNDENGRUPPEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("INSERT_KUNDENGRUPPEN")),
    [auth.user]
  );

  const DELETE_KUNDENGRUPPEN = useMemo(
    () => Boolean(auth.user?.permissions.includes("DELETE_KUNDENGRUPPEN")),
    [auth.user]
  );

  useEffect(() => {
    let cancel = false;

    if (READ_KUNDENGRUPPEN) {
      if (props.loadKundengruppen) {
        if (!cancel) {
          setLoading(true);
        }
        axios
          .get<Kundengruppe[]>("/kundengruppen")
          .then((response) => {
            if (!cancel) {
              setKundengruppen(response.data);
            }
          })
          .catch(handleApiError)
          .finally(() => {
            if (!cancel) {
              setLoading(false);
            }
          });
      } else {
        if (!cancel) {
          setLoading(false);
        }
      }
    } else {
      if (!cancel) {
        setLoading(false);
      }
    }

    return () => {
      cancel = true;
    };
  }, [READ_KUNDENGRUPPEN, handleApiError, props.loadKundengruppen]);

  const createKundengruppe = (data: Kundengruppe) => {
    if (CREATE_KUNDENGRUPPEN) {
      return axios.post<Kundengruppe>("/kundengruppen", data).then((response) => {
        const copy = [...kundengruppen];
        copy.unshift(response.data);
        setKundengruppen(copy);
        return response.data;
      });
    }
    throw new Error("unauthorized");
  };

  const deleteKundengruppe = (uuid: string) => {
    if (DELETE_KUNDENGRUPPEN) {
      return axios.delete("/kundengruppen/" + uuid).then(() => {
        const copy = [...kundengruppen];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === uuid) {
            copy.splice(i, 1);
            break;
          }
        }

        setKundengruppen(copy);
      });
    }
    throw new Error("unauthorized");
  };

  const getKundengruppe = (uuid: string) => {
    if (READ_KUNDENGRUPPEN) {
      return axios.get<Kundengruppe>("/kundengruppen/" + uuid).then((response) => response.data);
    }
    throw new Error("unauthorized");
  };

  const updateKundengruppe = (data: Kundengruppe) => {
    if (UPDATE_KUNDENGRUPPEN) {
      return axios.patch<Kundengruppe>("/kundengruppen", data).then((response) => {
        const copy = [...kundengruppen];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === response.data.uuid) {
            copy[i] = response.data;
            break;
          }
        }
        setKundengruppen(copy);
        return response.data;
      });
    }
    throw new Error("unauthorized");
  };

  return {
    kundengruppen,
    loading,
    getKundengruppe,
    updateKundengruppe,
    createKundengruppe,
    deleteKundengruppe,
    READ_KUNDENGRUPPEN,
    UPDATE_KUNDENGRUPPEN,
    CREATE_KUNDENGRUPPEN,
    DELETE_KUNDENGRUPPEN,
  };
};

import styled from "@emotion/styled";

export const ShopContainerStyled = styled("div", { shouldForwardProp: (prop) => prop !== "useSidebar" })<{
  useSidebar: boolean;
}>(({ useSidebar }) => ({
  height: "calc(100vh - 56px)",
  ...(useSidebar && {
    height: "100vh",
  }),
  width: "100%",
}));

import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavigationElement } from "../../providers/AuthProvider";

interface IProviderSidebar {
  rootpath: string;
  title: string;
  elements: NavigationElement[];
}

const ProviderSidebar: FC<IProviderSidebar> = (props) => {
  const location = useLocation();

  return (
    <div className={"w-[300px] bg-white border-r-1 border-solid border-gray-300 h-full flex flex-col"}>
      <Link to={props.rootpath} className={"px-3 py-4 text-sm"}>
        {props.title}
      </Link>
      {props.elements.map((element) => (
        <Link
          to={props.rootpath + element.to + (element.tab !== null ? "?tab=" + element.tab : "")}
          key={element.uuid}
          className={
            "w-full text-sm py-2 px-3 hover:bg-gray-100 cursor-pointer flex justify-start" +
            (location.pathname.startsWith(props.rootpath + element.to) ? " bg-gray-100" : "")
          }
        >
          {element.text}
        </Link>
      ))}
    </div>
  );
};

export default ProviderSidebar;

import "reflect-metadata";
import React, { FC, Suspense, useMemo } from "react";
import { LoadingPage, useAuth } from "./providers/AuthProvider";
import Navigation from "./components/Navigation/Navigation";
import Routes from "./routes";
import { Link, useLocation } from "react-router-dom";
import Logo from "./components/Layout/Logo";
import { useSidebar } from "./providers/SidebarProvider";
import SidebarIcon from "./components/Navigation/SidebarIcon";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import styled from "@emotion/styled";

const MainWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "sidebarOpen" })<{ sidebarOpen: boolean }>(
  ({ sidebarOpen }) => ({
    outline: "none",
    display: "grid",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    ...(!sidebarOpen && {
      gridTemplateColumns: "0px minmax(0, 1fr) 0px",
      gridTemplateRows: "0px 56px 0px",
      gridTemplateAreas:
        '"left-panel banner right-panel" "left-panel top-navigation right-panel" "left-panel content right-panel"',
    }),
    ...(sidebarOpen && {
      gridTemplateColumns: "0px minmax(0, 1fr) 0px",
      gridTemplateRows: "0px 0px 0px",
      gridTemplateAreas:
        '"left-panel banner right-panel" "left-panel top-navigation right-panel" "left-panel content right-panel"',
    }),
  })
);

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "sidebarOpen" && prop !== "hideNavbar" })<{
  hideNavbar: boolean;
  sidebarOpen: boolean;
}>(({ sidebarOpen, hideNavbar }) => ({
  height: "calc(100vh - 56px)",
  maxHeight: "calc(100vh - 56px)",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  gridArea: "content / content / content / content",
  ...(sidebarOpen &&
    !hideNavbar && {
      marginLeft: 70,
      height: "100vh",
    }),
}));

const Nav = styled("nav")(() => ({
  width: 70,
  height: "100vh",
  backgroundColor: "#fff",
  maxHeight: "100vh",
  display: "flex",
  flexShrink: 0,
  flexDirection: "column",
  alignItems: "center",
  gridArea: "left-panel / left-panel / left-panel / left-panel",
  borderRight: "1px solid rgba(0,0,0,.1)",
}));

const LogoLink = styled(Link)(() => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  padding: "0.75rem 0",
}));

const App: FC = () => {
  const auth = useAuth();
  const sidebar = useSidebar();
  const location = useLocation();

  const hideNavbar = useMemo(() => {
    if (!auth.loggedIn || !auth.user || auth.user.firstLogin) return true;
    for (let i = 0; i < auth.user.navigation.length; i++) {
      if (auth.user.navigation[i].hideNavbar && location.pathname.startsWith(auth.user.navigation[i].to)) {
        return true;
      }
    }
    return false;
  }, [auth.loggedIn, auth.user, location.pathname]);

  return (
    <MainWrapper sidebarOpen={sidebar.sidebarOpen}>
      {!auth.validating && auth.loggedIn && !hideNavbar && !sidebar.sidebarOpen && <Navigation />}
      {!auth.validating && auth.loggedIn && !hideNavbar && sidebar.sidebarOpen && (
        <Nav>
          <LogoLink to={"/"}>
            <Logo />
          </LogoLink>
          <div className={"h-full max-h-full flex flex-col overflow-y-auto w-full items-center"}>
            {auth.user?.navigation.map((group) => (
              <Link
                className={
                  "flex font-bold text-gray-500 flex-row items-center justify-center py-3 px-4 text-sm hover:bg-gray-100 w-full" +
                  (location.pathname.startsWith(group.to) ? " bg-gray-100" : "")
                }
                to={group.to}
                key={group.uuid}
                title={group.name}
              >
                <SidebarIcon to={group.to} />
              </Link>
            ))}
          </div>
          <button
            className={"w-full flex flex-row items-center justify-center pt-2 pb-4 mt-auto"}
            type={"button"}
            title={"Zur Navigations Ansicht wechseln"}
            onClick={() => {
              sidebar.toggleSidebar(false);
              sidebar.updateRemote(false);
            }}
          >
            <ArrowsRightLeftIcon className={"w-6 h-6"} />
          </button>
        </Nav>
      )}
      <Main sidebarOpen={sidebar.sidebarOpen} hideNavbar={hideNavbar}>
        <Suspense fallback={<LoadingPage />}>
          <Routes />
        </Suspense>
      </Main>
    </MainWrapper>
  );
};

export default App;

import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { useLocation } from "react-router-dom";
import ProviderSidebar from "../components/Layout/ProviderSidebar";
import axios from "axios";

interface IUseSidebarProvider {
  sidebarOpen: boolean;
  updateRemote: (open: boolean) => void;
  toggleSidebar: (open: boolean) => void;
}

const SidebarContext = createContext({} as IUseSidebarProvider);

const useSidebarProvider = (): IUseSidebarProvider => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const toggleSidebar = (open: boolean) => {
    setSidebarOpen(open);
  };

  const updateRemote = (open: boolean) => {
    if (open) {
      axios.patch("/auth/sidebar?state=yes").then(() => {});
    } else {
      axios.patch("/auth/sidebar?state=no").then(() => {});
    }
  };

  return {
    sidebarOpen,
    updateRemote,
    toggleSidebar,
  };
};

export const useSidebar = () => useContext(SidebarContext);

const SidebarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const sidebar = useSidebarProvider();
  const auth = useAuth();
  const location = useLocation();

  const elements = useMemo(() => {
    if (auth.loggedIn && auth.user !== null) {
      const basePath = location.pathname.split("/")[1];

      for (let i = 0; i < auth.user.navigation.length; i++) {
        if (auth.user.navigation[i].to === `/${basePath}`) {
          return auth.user.navigation[i].elements;
        }
      }
    }
    return [];
  }, [auth.loggedIn, auth.user, location.pathname]);

  return (
    <SidebarContext.Provider value={sidebar}>
      {sidebar.sidebarOpen && <ProviderSidebar title={"Finanzen"} rootpath={"/finanzen"} elements={elements} />}
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

import styled from "@emotion/styled";

const DotFlashing = styled("div")(() => ({
  position: "relative",
  width: 10,
  height: 10,
  borderRadius: 5,
  backgroundColor: "#0065ff",
  color: "#0065ff",
  animation: "dotFlashing 1s infinite linear alternate",
  animationDelay: ".5s",
  "&::before": {
    content: "''",
    display: "inline-block",
    position: "absolute",
    top: 0,
    left: -15,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#0065ff",
    color: "#0065ff",
    animation: "dotFlashing 1s infinite alternate",
    animationDelay: "0s",
  },
  "&::after": {
    content: "''",
    display: "inline-block",
    position: "absolute",
    top: 0,
    left: 15,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#0065ff",
    color: "#0065ff",
    animation: "dotFlashing 1s infinite alternate",
    animationDelay: "1s",
  },
}));

export default DotFlashing;
